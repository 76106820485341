import React, { useState } from 'react';
import { TextField, Button, Typography, Box, Grid, Snackbar, Alert } from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ClipLoader } from 'react-spinners';
import LoginImg from '../../assets/Images/Loginimg.jpg';
import Arrow from '../../assets/Images/Arrow.svg'
import './Auth.css'
import './Responsive.css'
import GlobalVariable from "../../services/Global";

const baseApiUrl = GlobalVariable.BASE_API_URL;

const FormContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(1),
  backgroundColor: '#fff',
  height: '100%',
  justifyContent: 'center',
}));
 
const Form = styled('form')(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(1),
}));
 

 
const Media = styled('img')({
  height: '100%',
});
 
const SpinnerContainer = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  zIndex: 1300,
}));
 
const ForgotPassword = () => {
  const navigate = useNavigate();
  const [inputData, setInputData] = useState('');
  const [error, setError] = useState('');
  const [loginError, setLoginError] = useState('');
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
 
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoginError('');
 
    if (validateEmail(inputData)) {
      setLoading(true);
 
      try {
        const response = await axios.post(`${baseApiUrl}/users/forgetpassword`, { email: inputData });
        if (response.status === 200) {
          setError('');
          setSnackbarSeverity('success');
          setSnackbarMessage('OTP sent successfully!');
          setSnackbarOpen(true);
          navigate("/otp-verify", { state: { email: inputData } });
        } else {
          setSnackbarSeverity('error');
          setSnackbarMessage('Error sending OTP, please try again.');
          setSnackbarOpen(true);
        }
      } catch (err) {
        if (err.response?.status === 404) {
          setError('This Email is not registred');
          // navigate('/notfoundd');
        } else {
          setLoginError(err.response?.data?.message || 'Failed to send OTP. Please check your email and try again.');
        }
      } finally {
        setLoading(false);
      }
    } else {
      setError('Please enter a valid email address');
    }
  };
 
  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.(com)$/;
    return re.test(email);
  };

  const handleKeyDown = (event) => {
    if (event.key === " ") {
      event.preventDefault();
    }
  };

 
  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputData(value);
 
    if (error && validateEmail(value)) {
      setError('');
    }
  };
 
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };
 
  return (
 
    <Grid container component="main" display='flex' sx={{ height: '100vh', width: window.width }}>
      <Grid item xs={12} sm={7} md={7} lg={8} className='hide-on-mobile'>
        <Media
          src={LoginImg}
          alt="Welcome"
          sx={{ width: '100%', height: '100%', objectFit: 'cover' }}
        />
      </Grid>
      <Grid item xs={12} sm={5} md={5} lg={4} className='position-relative' >
        <FormContainer>
          <Box className='position-absolute top-0 start-0 d-flex mt-4  mb-4 arrowIcon'>
            <img src={Arrow} alt="Arrow Icon"  onClick={() => navigate('/')} className='mt-2 arrowauthl'/>
          </Box>
          <Box className='scale-down position-absolute top-0 end-0 d-flex mt-3 mx-2' mb={4}>
            <Box>
            <Typography className='fontstyleeb fs-3'>Welcome To,</Typography>
              <Typography className='title'>
                 MIT BidSmart
              </Typography>
            </Box>
            <Box sx={{ marginLeft: '16px' }}>
              <Media src="Rfplogoicon.png" alt="Welcome" sx={{ width: '65px', height: '65px' }} />
            </Box>
          </Box>
          <div className='w-75' >
            <Typography className='fs-4 forgotpswtxt'>
              <b>Forgot Password ?</b>
            </Typography>
            <Typography Typography mb={2} className='loginsimplfyText'>
              Enter Your Recovery Email
            </Typography>
            <Form noValidate onSubmit={handleSubmit}>
            <Typography className ='labels'>Email</Typography>
              <TextField
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '15px',
                    '&:hover': {
                      borderColor: '#0D6EFD',
                    },
                    '&.Mui-focused': {
                      borderColor: '#0D6EFD',
                      '& fieldset': {
                        borderColor: '#0D6EFD',
                      },
                    },
                  },
                }}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                value={inputData}
                id="email"
                placeholder="Enter Email Address"
                name="email"
                autoComplete="email"
                className='textfield'
                autoFocus
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                error={Boolean(error)}
                helperText={error}
              />
              {loginError && (
                <Typography color="error" variant="body2">
                  {loginError}
                </Typography>
              )}
              <Button
                type="submit"
                margin="normal"
                fullWidth
                variant="contained"
                className='bg-primary text-capitalize rounded-4 mt-4 p-2'
              >
                Submit
              </Button>
            </Form>
          </div>
        </FormContainer>
      </Grid>
      {loading && (
        <SpinnerContainer>
          <ClipLoader size={50} color="#123abc" loading={loading} />
        </SpinnerContainer>
      )}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Grid>
 
  );
};
 
export default ForgotPassword;