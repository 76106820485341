import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Box,
  Card,
  Typography,
  Button,
  FormControl,
  TextField,
  MenuItem,
  Select,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress
} from "@mui/material";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "bootstrap/dist/css/bootstrap.min.css";
import PDF from "../../assets/Images/PDF.svg";
import dayjs from "dayjs";
import axios from "axios";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { StaticDateTimePicker } from "@mui/x-date-pickers/StaticDateTimePicker";
import CloseIcon from "@mui/icons-material/Close";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useSnackbar } from "../SnackbarContext";
import GlobalVariable from "../../services/Global";

const baseApiUrl = GlobalVariable.BASE_API_URL;

const customTheme = createTheme({
  palette: {
    primary: {
      main: "#1976d2",
    },
  },
});

const statusOptions = [
  { value: 1, label: "New" },
  { value: 4, label: "Submitted" },
  { value: 3, label: "On-Hold" },
  { value: 2, label: "In-Progress" },
];

const submissionModes = [
  { value: "Portal", label: "Portal" },
  { value: "Email", label: "Email" },
  { value: "Physical", label: "Physical" },
];

export default function ExtrctedPdfDocument() {
  const [errors, setErrors] = useState({
    firstReview: "",
    rfpIssuer: "",
    rfpType: "",
    location: "",
    budgetRange: "",
    expectedTimeline: "",
    website: "",
    name: "",
    email: "",
    conferenceDate: "",
    conferenceInformation: "",
    questionnaireDate: "",
    status: "",
    submissionMode: "",
    submissionDate: "",
    // remarks: '',
  });
  const [formData, setFormData] = useState({
    firstReview: "",
    rfpIssuer: "",
    rfpType: "",
    location: "",
    budgetRange: "",
    expectedTimeline: "",
    website: "",
    name: "",
    email: "",
    conferenceDate: "",
    conferenceInformation: "",
    questionnaireDate: "",
    status: "",
    submissionMode: "",
    submissionDate: "",
    // remarks: '',
    filename: "",
  });
  const location = useLocation();
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const { pdfText } = location.state;
  const [isFormValid, setIsFormValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [comments, setComments] = useState("");
  const [firstReviewDialogOpen, setfirstReviewDialogOpen] = useState(false);
  const [conferenceDialogOpen, setConferenceDialogOpen] = useState(false);
  const [QuestionnaireDialogOpen, setQuestionnaireDialogOpen] = useState(false);
  const [submissionDialogOpen, setSubmissionDialogOpen] = useState(false);
  const formatDateTime = (date) => {
    return dayjs(date).format("YYYY-MM-DD HH:mm");
  };

  useEffect(() => {
    if (pdfText) {
      setFormData({
        firstReview:
          pdfText.details?.first_review === ""
            ? ""
            : formatDateTime(pdfText.details?.first_review),
        rfpIssuer: pdfText.details?.rfp_issuer || "",
        rfpType: pdfText.details?.rfp_type || "",
        location: pdfText.details?.location || "",
        budgetRange: pdfText.details?.budget_range || "",
        expectedTimeline: pdfText.details?.expected_timeline || "",
        website: pdfText.details?.website || "",
        name: pdfText.details?.contact_name || "",
        email: pdfText.details?.email || "",
        conferenceDate:
          pdfText.details?.conference_date === null ||
            pdfText.details?.conference_date === ""
            ? ""
            : formatDateTime(pdfText.details?.conference_date),
        conferenceInformation:
          pdfText.details?.conference_information === ""
            ? "No information"
            : pdfText.details?.conference_information,
        questionnaireDate:
          pdfText.details?.questionnaire_date === null ||
            pdfText.details?.questionnaire_date === ""
            ? ""
            : formatDateTime(pdfText.details?.questionnaire_date),
        status: pdfText.details?.status || "",
        submissionMode: pdfText.details?.submission_mode || "",
        submissionDate:
          pdfText.details?.submission_date === "" ||
            pdfText.details?.submission_date === null
            ? ""
            : formatDateTime(pdfText.details?.submission_date),
        // remarks: pdfText.remarks?.remarks || '',
        filename: pdfText.filename,
      });
      setComments(pdfText?.comments.comments)
    }
  }, [pdfText]);

  useEffect(() => {
    const checkFormValidity = () => {
      const allFieldsValid = Object.values(errors).every((error) => !error);
      const allFieldsFilled = Object.values(formData).every((value) => {
        if (typeof value === "string") {
          return value.trim() !== "";
        } else if (typeof value === "number") {
          return value !== null && value !== undefined;
        } else {
          return value !== null && value !== undefined;
        }
      });

      setIsFormValid(allFieldsValid && allFieldsFilled);
    };

    checkFormValidity();
  }, [formData, errors]);

  const [selectedConferenceDate, setSelectedConferenceDate] = useState(
    formData.conferenceDate ? dayjs(formData.conferenceDate) : null
  );
  const [selectedQuestionnaireDate, setSelectedQuestionnaireDate] = useState(
    formData.questionnaireDate ? dayjs(formData.questionnaireDate) : null
  );
  const [selectedSubmissionDate, setSelectedSubmissionDate] = useState(
    formData.submissionDate ? dayjs(formData.submissionDate) : null
  );

  const validateField = (name, value) => {
    switch (name) {
      case "email":
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)
          ? ""
          : "Invalid email address";
      case "budgetRange":
        return /^(₹|₹ |\$|£|€|¥)? ?[0-9]+$/.test(value) ? "" : "Budget Range can numbers with symbol(₹ | $)";
      case "website":
        return /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+$/.test(value)
        ? ""
         : "Invalid website URL";
      case "conferenceInformation":
        return value ? "" : "Conference Information is required";
      case "submissionMode":
        return value ? "" : "Submission mode is required";
      case "status":
        return value ? "" : "Status is required";
      case "rfpIssuer":
        return value ? "" : "RFP Issuer is required";
      case "rfpType":
        return value ? "" : "RFP Type is required";
      case "location":
        return value ? "" : "Location is required";
      case "name":
        return value ? "" : "Name is required";
      case "firstReview":
      case "expectedTimeline":
      case "conferenceDate":
      case "questionnaireDate":
      case "submissionDate":
        return value ? "" : "Date is required";
      default:
        return "";
    }
  };

  const handleSave = async () => {
    setLoading(true);
    if (!isFormValid) return;
    let hasErrors = false;
    const newErrors = {};

    for (const [key, value] of Object.entries(formData)) {
      const error = validateField(key, value);
      if (error) {
        newErrors[key] = error;
        hasErrors = true;
      }
    }

    setErrors(newErrors);

    if (hasErrors) return;
    const storedData = JSON.parse(localStorage.getItem("userDetails"));
    const userId = storedData?.userDetails?.id;

    if (!userId) {
      console.error("User ID not found in local storage");
      navigate("/404");
      return;
    }

    const data = {
      details: {
        userid: userId,
        first_review: formatDateTime(formData?.firstReview),
        rfp_issuer: formData.rfpIssuer,
        rfp_type: formData.rfpType,
        location: formData.location,
        budget_range: formData.budgetRange,
        expected_timeline: formData.expectedTimeline,
        website: formData.website,
        contact_name: formData.name,
        contact_email: formData.email,
        conference_date: formatDateTime(formData?.conferenceDate),
        conference_information: formData.conferenceInformation,
        questionnaire_date: formatDateTime(formData?.questionnaireDate),
        submission_mode: formData.submissionMode,
        submission_date: formatDateTime(formData?.submissionDate),
        status: formData.status,
        conference_reason: "",
        questionnaire_reason: "",
      },
      remarks: {
        userid: userId,
        remarks: "",
      },
      comments: {
        userid: userId,
        comments: comments,
      },
      filename: formData.filename,
    };

    axios
      .post(`${baseApiUrl}/dashboard/addrfp`,
        data
      )
      .then((response) => {
        showSnackbar("RFP added successfully.", "success");
        navigate("/dashboard");
        setLoading(false);
      })
      .catch((error) => {
        console.error("There was an error saving the RFP:", error);
        navigate("/404");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    const error = validateField(name, value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
  };

  const handleRfpCancel = () => {
    navigate("/dashboard");
  };

  const handleDateReviewAccept = (newValue) => {
    if (newValue) {
      handleInputChange({
        target: {
          name: "firstReview",
          value: newValue.toISOString(),
        },
      });
    }
    setfirstReviewDialogOpen(false);
  };

  const handleConferenceDateAccept = (newValue) => {
    if (newValue) {
      handleInputChange({
        target: {
          name: "conferenceDate",
          value: newValue.toISOString(),
        },
      });
    }
    setConferenceDialogOpen(false);
  };

  const handleQuestionnaireDateAccept = (newValue) => {
    if (newValue) {
      handleInputChange({
        target: {
          name: "questionnaireDate",
          value: newValue.toISOString(),
        },
      });
    }
    setQuestionnaireDialogOpen(false);
  };

  const handleSubmissionDateAccept = (newValue) => {
    if (newValue) {
      handleInputChange({
        target: {
          name: "submissionDate",
          value: newValue.toISOString(),
        },
      });
    }
    setSubmissionDialogOpen(false);
  };


  return (
    <Container fluid className="ps-3 mt-2 rfpDtailsContainer">
      {loading && (
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 1000,
          }}
        >
          <CircularProgress className=' text-primary' />
        </Box>
      )}
      <Card className="rounded-3">
        <Box style={{ paddingBottom: "16px" }}>
          <div style={{ padding: "16px 0" }}>
            <Row className="mb-2 align-items-center mx-1">
              <Col xs={12} sm={6} md={6}>
                <Box display="flex" alignItems="center">
                  <IconButton>
                    <img src={PDF} alt="pdf" width="25px" height="25px" />
                  </IconButton>
                  <span
                    className="extractedPdfName"
                    style={{
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                    }}
                  >
                    {(pdfText && pdfText.filename) || "MicrosoftRFP.pdf"}
                  </span>
                </Box>
              </Col>
              <Col
                xs={12}
                sm={6}
                md={6}
                className=" d-flex justify-content-end"
              >
                <Button
                  className="border-primary text-primary text-capitalize rounded-2 "
                  variant="outlined"
                  color="primary"
                  onClick={handleRfpCancel}
                >
                  Back
                </Button>
              </Col>
            </Row>
          </div>
          <form style={{ marginLeft: "15px", marginTop: "6px" }}>
            <Container fluid>

              <Box
                sx={{
                  border: "1px solid #ccc",
                  borderRadius: "10px",
                  padding: "16px",
                  position: "relative",
                  marginTop: "6px",
                }}
                className="mb-5"
              >
                <Typography
                  sx={{
                    position: "absolute",
                    top: "-10px",
                    left: "20px",
                    background: "#fff",
                    padding: "0 8px",
                    fontSize: "14px",
                  }}
                  className="fontstyleeb"
                >
                  RFP Details
                </Typography>
                <Row className="pb-2">
                  <Col lg={3} md={6} sm={12}>
                    <FormControl fullWidth margin="normal" className="mt-2 inputfields">
                      <Typography
                        className="fontstyleet"
                        variant="h6"
                        gutterBottom
                      >
                        First Review<span style={{ color: "#ff0000" }}>*</span>
                      </Typography>
                      <TextField
                        name="firstReview"
                        value={
                          formData.firstReview
                            ? dayjs(formData.firstReview).format(
                              "YYYY-MM-DD HH:mm"
                            )
                            : ""
                        }
                        placeholder="Select date"
                        InputProps={{
                          readOnly: true,
                          endAdornment: (
                            <IconButton
                              onClick={() => setfirstReviewDialogOpen(true)}
                              color="primary"
                              size="large"
                            >
                              <CalendarMonthIcon sx={{ color: "#0175FF" }} />
                            </IconButton>
                          ),
                        }}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "10px",
                          },
                          "& .MuiInputBase-root": {
                            height: "40px",
                          },
                        }}
                      />
                      {errors.firstReview && (
                        <Typography color="error">
                          {errors.firstReview}
                        </Typography>
                      )}

                      <Dialog
                        open={firstReviewDialogOpen}
                        onClose={() => setfirstReviewDialogOpen(false)}
                        maxWidth="xs"
                        fullWidth
                        PaperProps={{
                          style: {
                            height: "85%",
                            width: "100%",
                            borderRadius: "12px",
                            position: "fixed",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            overflow: "auto",
                          },
                        }}
                      >
                        <DialogTitle>
                          Select First Review and Time
                          <IconButton
                            edge="end"
                            color="inherit"
                            onClick={() => setfirstReviewDialogOpen(false)}
                            aria-label="close"
                            aria-hidden={false}
                            aria-modal={true}
                            sx={{
                              position: "absolute",
                              right: 9,
                              top: 8,
                              color: (theme) => theme.palette.grey[500],
                            }}
                          >
                            <CloseIcon />
                          </IconButton>
                        </DialogTitle>
                        <DialogContent>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <ThemeProvider theme={customTheme}>
                              <StaticDateTimePicker
                                value={selectedConferenceDate}
                                minDate={dayjs("2024-04-01 12:00")}
                                onAccept={(newValue) =>
                                  handleDateReviewAccept(newValue)
                                }
                                onClose={() => setfirstReviewDialogOpen(false)}
                                ampm={false}
                              />
                            </ThemeProvider>
                          </LocalizationProvider>
                        </DialogContent>
                      </Dialog>
                    </FormControl>
                  </Col>
                  <Col lg={6} md={6} sm={12}>
                    <FormControl fullWidth margin="normal" className="inputfields">
                      <Typography>RFP ISSUER<span style={{ color: "#ff0000" }}>*</span></Typography>
                      <TextField
                        placeholder="Issuer Name"
                        name="rfpIssuer"
                        autoComplete="off"
                        onChange={handleInputChange}
                        value={formData.rfpIssuer}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "10px",
                          },
                          "& .MuiInputBase-root": {
                            height: "40px",
                          },
                        }}
                      />
                      {errors.rfpIssuer && (
                        <Typography color="error">
                          {errors.rfpIssuer}
                        </Typography>
                      )}
                    </FormControl>
                  </Col>
                  <Col lg={3} md={12} sm={12}>
                    <FormControl fullWidth margin="normal" className="inputfields">
                      <Typography>Rfp Type<span style={{ color: "#ff0000" }}>*</span></Typography>
                      <Select
                        value={formData.rfpType}
                        onChange={handleInputChange}
                        name="rfpType"
                        sx={{
                          "& .MuiOutlinedInput-root": { borderRadius: "10px" },
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderRadius: "10px",
                            height: "45px",
                          },
                          "& .MuiSelect-icon": { top: "8px", color: "blue" },
                          "& .MuiOutlinedInput-input": { paddingTop: "9px" },
                        }}
                      >
                        <MenuItem className="subheader" value="Solutioning">
                          Solutioning
                        </MenuItem>
                        <MenuItem className="subheader" value="Staffing">
                          Staffing
                        </MenuItem>
                      </Select>
                      {errors.rfpType && (
                        <Typography color="error">{errors.rfpType}</Typography>
                      )}
                    </FormControl>
                  </Col>
                </Row>
                <Row>
                  <Col lg={3} md={6} sm={12}>
                    <FormControl fullWidth margin="normal" className="inputfields">
                      <Typography>Location<span style={{ color: "#ff0000" }}>*</span></Typography>
                      <TextField
                        placeholder="Location"
                        name="location"
                        autoComplete="off"
                        value={formData.location}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "10px",
                          },
                          "& .MuiInputBase-root": {
                            height: "40px",
                          },
                        }}
                        onChange={handleInputChange}
                      />
                      {errors.location && (
                        <Typography color="error">{errors.location}</Typography>
                      )}
                    </FormControl>
                  </Col>
                  <Col lg={3} md={6} sm={12}>
                    <FormControl fullWidth margin="normal" className="inputfields">
                      <Typography>Budget Range(₹ or $)<span style={{ color: "#ff0000" }}>*</span></Typography>
                      <TextField
                        placeholder="Budget Range"
                        name="budgetRange"
                        autoComplete="off"
                        value={formData.budgetRange}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "10px",
                          },
                          "& .MuiInputBase-root": {
                            height: "40px",
                          },
                        }}
                        onChange={handleInputChange}
                      />
                      {errors.budgetRange && (
                        <Typography color="error">
                          {errors.budgetRange}
                        </Typography>
                      )}
                    </FormControl>
                  </Col>
                  <Col lg={3} md={12} sm={12}>
                    <FormControl fullWidth margin="normal" className="inputfields">
                      <Typography>Expected Timeline(In months)<span style={{ color: "#ff0000" }}>*</span></Typography>
                      <TextField
                        placeholder="Expected Timeline"
                        name="expectedTimeline"
                        autoComplete="off"
                        value={formData.expectedTimeline}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "10px",
                          },
                          "& .MuiInputBase-root": {
                            height: "40px",
                          },
                        }}
                        onChange={handleInputChange}
                      />
                      {errors.expectedTimeline && (
                        <Typography color="error">
                          {errors.expectedTimeline}
                        </Typography>
                      )}
                    </FormControl>
                  </Col>
                  <Col lg={3} md={12} sm={12} xs={12} className="inputfields">
                    <FormControl fullWidth margin="normal">
                      <Typography>Website<span style={{ color: "#ff0000" }}>*</span></Typography>
                      <TextField
                        placeholder="Website"
                        name="website"
                        autoComplete="off"
                        value={formData.website}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "10px",
                          },
                          "& .MuiInputBase-root": {
                            height: "40px",
                          },
                        }}
                        onChange={handleInputChange}
                      />
                      {errors.website && (
                        <Typography color="error">{errors.website}</Typography>
                      )}
                    </FormControl>
                  </Col>
                </Row>
              </Box>
              <Box
                sx={{
                  border: "1px solid #ccc",
                  borderRadius: "10px",
                  padding: "16px",

                  position: "relative",
                }}
                className="mb-5"
              >
                <Typography
                  sx={{
                    position: "absolute",
                    top: "-10px",
                    left: "20px",
                    background: "#fff",
                    padding: "0 8px",
                    fontSize: "14px",
                    fontWeight: "bold",
                  }}
                >
                  Point of Contact Details
                </Typography>
                <Row>
                  <Col lg={3}>
                    <FormControl fullWidth margin="normal" className="inputfields">
                      <Typography>Name<span style={{ color: "#ff0000" }}>*</span></Typography>
                      <TextField
                        placeholder="Name"
                        name="name"
                        value={formData.name}
                        autoComplete="off"
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "10px",
                          },
                          "& .MuiInputBase-root": {
                            height: "40px",
                          },
                        }}
                        onChange={handleInputChange}
                      />
                      {errors.name && (
                        <Typography color="error">{errors.name}</Typography>
                      )}
                    </FormControl>
                  </Col>
                  <Col lg={3}>
                    <FormControl fullWidth margin="normal" className="inputfields">
                      <Typography>Email<span style={{ color: "#ff0000" }}>*</span></Typography>
                      <TextField
                        placeholder="Email"
                        name="email"
                        value={formData.email}
                        autoComplete="off"
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "10px",
                          },
                          "& .MuiInputBase-root": {
                            height: "40px",
                          },
                        }}
                        onChange={handleInputChange}
                      />
                      {errors.email && (
                        <Typography color="error">{errors.email}</Typography>
                      )}
                    </FormControl>
                  </Col>
                </Row>
              </Box>
              <Box
                sx={{
                  border: "1px solid #ccc",
                  borderRadius: "10px",
                  padding: "16px",

                  position: "relative",
                }}
                className="mb-5"
              >
                <Typography
                  sx={{
                    position: "absolute",
                    top: "-10px",
                    left: "20px",
                    background: "#fff",
                    padding: "0 8px",
                    fontSize: "14px",
                    fontWeight: "bold",
                  }}
                >
                  Submission Details
                </Typography>
                <Row className="pb-2">
                  <Col lg={3} md={6} sm={12} xs={12}>
                    <FormControl fullWidth margin="normal" className="mt-2 inputfields">
                      <Typography
                        variant="h6"
                        className="fontstyleet"
                        gutterBottom
                      >
                        Conference Date<span style={{ color: "#ff0000" }}>*</span>
                      </Typography>
                      <TextField
                        name="conferenceDate"
                        value={
                          formData.conferenceDate
                            ? dayjs(formData.conferenceDate).format(
                              "YYYY-MM-DD HH:mm"
                            )
                            : ""
                        }
                        placeholder="Select date"
                        InputProps={{
                          readOnly: true,
                          endAdornment: (
                            <IconButton
                              onClick={() => setConferenceDialogOpen(true)}
                              color="primary"
                              size="large"
                            >
                              <CalendarMonthIcon sx={{ color: "#0175FF" }} />
                            </IconButton>
                          ),
                        }}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "10px",
                          },
                          "& .MuiInputBase-root": {
                            height: "40px",
                          },
                        }}
                      />
                      {errors.conferenceDate && (
                        <Typography color="error">
                          {errors.conferenceDate}
                        </Typography>
                      )}

                      <Dialog
                        open={conferenceDialogOpen}
                        onClose={() => setConferenceDialogOpen(false)}
                        maxWidth="xs"
                        fullWidth
                        PaperProps={{
                          style: {
                            height: "85%",
                            width: "100%",
                            borderRadius: "12px",
                            position: "fixed",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            overflow: "auto",
                          },
                        }}
                      >
                        <DialogTitle>
                          Select Conference Date and Time
                          <IconButton
                            edge="end"
                            color="inherit"
                            onClick={() => setConferenceDialogOpen(false)}
                            aria-label="close"
                            sx={{
                              position: "absolute",
                              right: 9,
                              top: 8,
                              color: (theme) => theme.palette.grey[500],
                            }}
                          >
                            <CloseIcon />
                          </IconButton>
                        </DialogTitle>
                        <DialogContent>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <ThemeProvider theme={customTheme}>
                              <StaticDateTimePicker
                                value={selectedConferenceDate}
                                minDate={dayjs("2024-10-01")}
                                onAccept={(newValue) =>
                                  handleConferenceDateAccept(newValue)
                                }
                                onClose={() => setConferenceDialogOpen(false)}
                                ampm={false}
                              />
                            </ThemeProvider>
                          </LocalizationProvider>
                        </DialogContent>
                      </Dialog>
                    </FormControl>
                  </Col>
                  <Col lg={3} md={6}>
                    <FormControl fullWidth margin="normal" className="inputfields">
                      <Typography>Conference Information<span style={{ color: "#ff0000" }}>*</span></Typography>
                      <TextField
                        name="conferenceInformation"
                        autoComplete="off"
                        value={formData.conferenceInformation}
                        onChange={handleInputChange}
                        placeholder="Conference Information"
                        variant="outlined"
                        fullWidth
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "10px",
                          },
                          "& .MuiInputBase-root": {
                            height: "40px",
                          },
                        }}
                      />
                      {errors.conferenceInformation && (
                        <Typography color="error" sx={{ fontSize: "0.75rem" }}>
                          {errors.conferenceInformation}
                        </Typography>
                      )}
                    </FormControl>
                  </Col>
                  <Col lg={3} md={6} sm={12} xs={12}>
                    <FormControl fullWidth margin="normal" className="mt-2 inputfields">
                      <Typography
                        variant="h6"
                        className="fontstyleet"
                        gutterBottom
                      >
                        Questionnaire Date<span style={{ color: "#ff0000" }}>*</span>
                      </Typography>
                      <TextField
                        name="questionnaireDate"
                        value={
                          formData.questionnaireDate
                            ? dayjs(formData.questionnaireDate).format(
                              "YYYY-MM-DD HH:mm"
                            )
                            : ""
                        }
                        onChange={(e) => handleInputChange(e)}
                        placeholder="Select date"
                        InputProps={{
                          readOnly: true,
                          endAdornment: (
                            <IconButton
                              onClick={() => setQuestionnaireDialogOpen(true)}
                              color="primary"
                              size="large"
                            >
                              <CalendarMonthIcon sx={{ color: "#0175FF" }} />
                            </IconButton>
                          ),
                        }}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "10px",
                          },
                          "& .MuiInputBase-root": {
                            height: "40px",
                          },
                        }}
                      />
                      {errors.questionnaireDate && (
                        <Typography color="error">
                          {errors.questionnaireDate}
                        </Typography>
                      )}

                      <Dialog
                        open={QuestionnaireDialogOpen}
                        onClose={() => setQuestionnaireDialogOpen(false)}
                        maxWidth="xs"
                        fullWidth
                        PaperProps={{
                          style: {
                            height: "85%",
                            width: "100%",
                            borderRadius: "12px",
                            position: "fixed",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            overflow: "auto",
                          },
                        }}
                      >
                        <DialogTitle>
                          Select Questionnaire Date and Time
                          <IconButton
                            edge="end"
                            color="inherit"
                            onClick={() => setQuestionnaireDialogOpen(false)}
                            aria-label="close"
                            sx={{
                              position: "absolute",
                              right: 9,
                              top: 8,
                              color: (theme) => theme.palette.grey[500],
                            }}
                          >
                            <CloseIcon />
                          </IconButton>
                        </DialogTitle>
                        <DialogContent>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <ThemeProvider theme={customTheme}>
                              <StaticDateTimePicker
                                value={selectedQuestionnaireDate}
                                minDate={dayjs("2024-10-01")}
                                onAccept={(newValue) =>
                                  handleQuestionnaireDateAccept(newValue)
                                }
                                onClose={() => setQuestionnaireDialogOpen(false)}
                                ampm={false}
                              />
                            </ThemeProvider>
                          </LocalizationProvider>
                        </DialogContent>
                      </Dialog>
                    </FormControl>
                  </Col>
                  <Col lg={3} md={6} sm={12} xs={12}>
                    <FormControl fullWidth margin="normal" className="inputfields">
                      <Typography>Status<span style={{ color: "#ff0000" }}>*</span></Typography>
                      <Select
                        value={formData.status}
                        onChange={handleInputChange}
                        name="status"
                        sx={{
                          "& .MuiOutlinedInput-root": { borderRadius: "10px" },
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderRadius: "10px",
                            height: "45px",
                          },
                          "& .MuiSelect-icon": { top: "8px", color: "blue" },
                          "& .MuiOutlinedInput-input": { paddingTop: "9px" },
                        }}
                      >
                        {statusOptions.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                      {errors.status && (
                        <Typography color="error">{errors.status}</Typography>
                      )}
                    </FormControl>
                  </Col>
                </Row>
                <Row>
                  <Col lg={3} md={6} sm={12} xs={12}>
                    <FormControl fullWidth margin="normal" className="inputfields">
                      <Typography>Submission Mode<span style={{ color: "#ff0000" }}>*</span></Typography>
                      <Select
                        value={formData.submissionMode}
                        onChange={handleInputChange}
                        name="submissionMode"
                        sx={{
                          "& .MuiOutlinedInput-root": { borderRadius: "10px" },
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderRadius: "10px",
                            height: "45px",
                          },
                          "& .MuiSelect-icon": { top: "8px", color: "blue" },
                          "& .MuiOutlinedInput-input": { paddingTop: "9px" },
                        }}
                      >
                        {submissionModes.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                      {errors.submissionMode && (
                        <Typography color="error">
                          {errors.submissionMode}
                        </Typography>
                      )}
                    </FormControl>
                  </Col>
                  <Col lg={3} md={6} sm={12} xs={12}>
                    <FormControl fullWidth margin="normal" className="mt-2 inputfields">
                      <Typography
                        variant="h6"
                        className="fontstyleet"
                        gutterBottom
                      >
                        Submission Date<span style={{ color: "#ff0000" }}>*</span>
                      </Typography>
                      <TextField
                        name="submissionDate"
                        value={
                          formData.submissionDate
                            ? dayjs(formData.submissionDate).format(
                              "YYYY-MM-DD HH:mm"
                            )
                            : ""
                        }
                        onChange={(e) => handleInputChange(e)}
                        placeholder="Select date"
                        InputProps={{
                          readOnly: true,
                          endAdornment: (
                            <IconButton
                              onClick={() => setSubmissionDialogOpen(true)}
                              color="primary"
                              size="large"
                            >
                              <CalendarMonthIcon sx={{ color: "#0175FF" }} />
                            </IconButton>
                          ),
                        }}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "10px",
                          },
                          "& .MuiInputBase-root": {
                            height: "40px",
                          },
                        }}
                      />
                      {errors.submissionDate && (
                        <Typography color="error">
                          {errors.submissionDate}
                        </Typography>
                      )}

                      <Dialog
                        open={submissionDialogOpen}
                        onClose={() => setSubmissionDialogOpen(false)}
                        maxWidth="xs"
                        fullWidth
                        PaperProps={{
                          style: {
                            height: "85%",
                            width: "100%",
                            borderRadius: "12px",
                            position: "fixed",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            overflow: "auto",
                          },
                        }}
                      >
                        <DialogTitle>
                          Select Submission Date and Time
                          <IconButton
                            edge="end"
                            color="inherit"
                            onClick={() => setSubmissionDialogOpen(false)}
                            aria-label="close"
                            sx={{
                              position: "absolute",
                              right: 9,
                              top: 8,
                              color: (theme) => theme.palette.grey[500],
                            }}
                          >
                            <CloseIcon />
                          </IconButton>
                        </DialogTitle>
                        <DialogContent>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <ThemeProvider theme={customTheme}>
                              <StaticDateTimePicker
                                value={selectedSubmissionDate}
                                minDate={dayjs()}
                                onAccept={(newValue) =>
                                  handleSubmissionDateAccept(newValue)
                                }
                                onClose={() => setSubmissionDialogOpen(false)}
                                ampm={false}
                              />
                            </ThemeProvider>
                          </LocalizationProvider>
                        </DialogContent>
                      </Dialog>
                    </FormControl>
                  </Col>
                </Row>
              </Box>
              <Box
                sx={{
                  border: "1px solid #ccc",
                  borderRadius: "10px",
                  padding: "16px",

                  position: "relative",
                }}
              >
                <Typography
                  sx={{
                    position: "absolute",
                    top: "-10px",
                    left: "20px",
                    background: "#fff",
                    padding: "0 8px",
                    fontSize: "14px",
                    fontWeight: "bold",
                  }}
                >
                  Additional Details
                </Typography>
                <Row>
                  <Col lg={5} md={6} sm={12} xs={12}>
                    <FormControl fullWidth margin="normal" className="inputfields">
                      <Typography>Comments</Typography>
                      <TextField
                        placeholder="Comments"
                        autoComplete="off"
                        name="comments"
                        value={comments}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "10px",
                          },
                          "& .MuiInputBase-root": {
                            height: "40px",
                          },
                        }}
                        onChange={(e) => setComments(e.target.value)}
                      />
                      {errors.comments && (
                        <Typography color="error">{errors.comments}</Typography>
                      )}
                    </FormControl>
                  </Col>
                </Row>
              </Box>
              <Row className="d-flex justify-content-center">
                <Col lg={3}></Col>
                <Col lg={9}>
                  <Box display="flex" justifyContent="end">
                    <Button
                      variant="contained"
                      sx={{ marginTop: 2 }}
                      disabled={!isFormValid}
                      onClick={handleSave}
                      className={` text-capitalize rounded-3 ${isFormValid ? "button-enabled" : "button-disabled"}`}
                    >
                      Save
                    </Button>
                  </Box>
                </Col>
              </Row>
            </Container>
          </form>
        </Box>
      </Card>

    </Container>
  );
}