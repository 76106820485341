import React, { useState } from 'react';
import axios from 'axios';
import {
  TextField,
  Button,
  Typography,
  Box,
  Grid,
  CircularProgress,
  MenuItem,
  Select,
  FormControl,
  FormHelperText,
  Snackbar,
  Alert,
  IconButton, InputAdornment
} from '@mui/material';
import { styled } from '@mui/system';
import { Link, useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import LoginImg from '../../assets/Images/Loginimg.jpg';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import './Responsive.css'
import GlobalVariable from "../../services/Global";


const baseApiUrl = GlobalVariable.BASE_API_URL;

const FormContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  height: '100%',
  justifyContent: 'center',
}));

const Form = styled('form')(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(1),
  height: '90%'
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(3, 0, 2),
}));

const Media = styled('img')({
  height: '100%',
});

const SpinnerContainer = styled(Box)({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'rgba(255, 255, 255, 0.8)',
  zIndex: 9999,
});

const Signup = () => {
  const navigate = useNavigate();
  const [formValues, setFormValues] = useState({
    email: '',
    userName: '',
    password: '',
    confirmPassword: '',
    userRole: '',
  });

  const [formErrors, setFormErrors] = useState({
    email: '',
    userName: '',
    password: '',
    confirmPassword: '',
    userRole: '',
  });

  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));

    let updatedErrors = { ...formErrors };

    if (name === 'email' && formErrors.email && /\S+@\S+\.\S+/.test(value)) {
      updatedErrors.email = '';
    }

    if (name === 'userName' && formErrors.userName && value.trim() !== '') {
      updatedErrors.userName = '';
    }

    if (name === 'password') {
      if (formErrors.password && value.length >= 6) {
        updatedErrors.password = '';
      }
      if (formErrors.confirmPassword && value === formValues.confirmPassword) {
        updatedErrors.confirmPassword = '';
      }
    }

    if (name === 'confirmPassword' && formErrors.confirmPassword && value === formValues.password) {
      updatedErrors.confirmPassword = '';
    }

    if (name === 'userRole' && formErrors.userRole && value.trim() !== '') {
      updatedErrors.userRole = '';
    }

    setFormErrors(updatedErrors);
  };

  const handleKeyDown = (event) => {
    if (event.key === " ") {
      event.preventDefault();
    }
  };


  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const validate = () => {
    let errors = {};
    let isValid = true;

    const emailPattern = /^[^\s@]+@[^\s@]+\.(com)$/;
    const noSpaces = /^\S+$/;
    const noConsecutiveSymbols = /^(?!.*[@]{2,})(?!.*[.]{2,}).*$/;
    const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

    if (!formValues.email) {
      isValid = false;
      errors.email = 'Email is required';
    } else if (!emailPattern.test(formValues.email)) {
      isValid = false;
      errors.email = 'Email is not a valid email"';
    } else if (!noSpaces.test(formValues.email)) {
      isValid = false;
      errors.email = 'Email should not contain spaces';
    } else if (!noConsecutiveSymbols.test(formValues.email)) {
      isValid = false;
      errors.email = 'Email should not contain repeated symbols';
    }

    if (!formValues.userName) {
      isValid = false;
      errors.userName = 'Username is required';
    } else if (formValues.userName.length < 6) {
      isValid = false;
      errors.userName = 'Username must be at least 6 characters';
    }

    if (!formValues.password) {
      isValid = false;
      errors.password = 'Password is required';
    } else if (!passwordPattern.test(formValues.password)) {
      isValid = false;
      errors.password = 'Password: min 8 chars, 1 uppercase, 1 lowercase, 1 digit, 1 special character';
    }

    if (!formValues.confirmPassword) {
      isValid = false;
      errors.confirmPassword = 'Confirm Password is required';
    } else if (formValues.password !== formValues.confirmPassword) {
      isValid = false;
      errors.confirmPassword = 'Passwords do not match';
    }

    if (!formValues.userRole) {
      isValid = false;
      errors.userRole = 'Role is required';
    }

    setFormErrors(errors);
    return isValid;
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      try {
        const response = await axios.post( `${baseApiUrl}/users/signup`, formValues);
        
        if (response.status === 200) {
          setSnackbarMessage('Account created successfully!');
          setSnackbarSeverity('success');
          setSnackbarOpen(true);
          setTimeout(() => {
            navigate('/');
          }, 1500);
        } else {
          setSnackbarMessage(response.data.details || 'Signup failed');
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 404) {
            navigate('/notfoundd');
          } else if (error.response.status === 409) {
            setSnackbarMessage('User already exists');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
          } else {
            setSnackbarMessage('Signup failed');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
          }
        } else {
          setSnackbarMessage('Network error');
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
        }
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Grid
      container
      component="main"
      display="flex"
      sx={{ boxSizing: 'border-box', height: '100vh', width: '100%' }}
    >
      <Grid item xs={false} sm={7} md={8} lg={8} className="hide-on-mobile">
        <Media
          src={LoginImg}
          alt="Welcome"
          sx={{ width: '100%', height: '100%', objectFit: 'cover' }}
        />
      </Grid>
      <Grid item xs={12} sm={5} md={4} lg={4} className="form-container">
        <FormContainer>
          <Box className='scale-down position-absolute top-0 end-0 d-flex mt-3 mx-2' mb={4}>
            <Box>
              <Typography className='fontstyleeb' sx={{fontSize: "20px"}}>Welcome To,</Typography>
              <Typography className='title'>
                MIT BidSmart
              </Typography>
            </Box>
            <Box sx={{ marginLeft: '16px' }}>
              <Media src="Rfplogoicon.png" alt="Welcome" sx={{ width: '65px', height: '65px' }} />
            </Box>
          </Box>
          <div className='w-75 mt-5 signpage'>
            <div className='text11'>
              <Typography variant="h4" className='cretenewacnttxt'>
                <strong>Create New Account</strong>
              </Typography>
              <Typography className='loginsimplfyText'>
                Enter Your Details
              </Typography>
            </div>
            <Form noValidate onSubmit={handleSubmit}>
              <Typography className='labels'>Email</Typography>
              <TextField
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '15px',
                    '&:hover': {
                      borderColor: '#0D6EFD',
                    },
                    '&.Mui-focused': {
                      borderColor: '#0D6EFD',
                      '& fieldset': {
                        borderColor: '#0D6EFD',
                      },
                    },
                  },
                }}
                variant="outlined"
                margin="dense"
                required
                fullWidth
                id="email"
                name="email"
                placeholder="Enter Email Address"
                autoComplete="email"
                autoFocus
                value={formValues.email}
                className='createpagetextfields'
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                error={!!formErrors.email}
                helperText={formErrors.email}

              />
              <Typography className='labels'>Username</Typography>
              <TextField
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '15px',
                    '&:hover': {
                      borderColor: '#0D6EFD',
                    },
                    '&.Mui-focused': {
                      borderColor: '#0D6EFD',
                      '& fieldset': {
                        borderColor: '#0D6EFD',
                      },
                    },
                  },
                }}
                variant="outlined"
                margin="dense"
                required
                fullWidth
                id="userName"
                name="userName"
                placeholder="Enter Username"
                autoComplete="userName"
                className='createpagetextfields'
                value={formValues.userName}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                error={!!formErrors.userName}
                helperText={formErrors.userName}
              />
              <Typography className='labels'>Password</Typography>
              <TextField
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '15px',
                    '&:hover': {
                      borderColor: '#0D6EFD',
                    },
                    '&.Mui-focused': {
                      borderColor: '#0D6EFD',
                      '& fieldset': {
                        borderColor: '#0D6EFD',
                      },
                    },
                  },
                }}
                variant="outlined"
                margin="dense"
                required
                fullWidth
                name="password"
                placeholder="Enter Password"
                type={showPassword ? "text" : "password"}
                id="password"
                className='createpagetextfields'
                autoComplete="current-password"
                value={formValues.password}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                error={!!formErrors.password}
                helperText={formErrors.password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Typography className='labels'>Confirm-Password</Typography>
              <TextField
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '15px',
                    '&:hover': {
                      borderColor: '#0D6EFD',
                    },
                    '&.Mui-focused': {
                      borderColor: '#0D6EFD',
                      '& fieldset': {
                        borderColor: '#0D6EFD',
                      },
                    },
                  },
                }}
                variant="outlined"
                margin="dense"
                required
                fullWidth
                name="confirmPassword"
                placeholder="Confirm-Password"
                type={showConfirmPassword ? "text" : "password"}
                id="confirmPassword"
                autoComplete="current-password"
                className='createpagetextfields'
                value={formValues.confirmPassword}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                error={!!formErrors.confirmPassword}
                helperText={formErrors.confirmPassword}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle confirm password visibility"
                        onClick={handleClickShowConfirmPassword}
                        edge="end"
                      >
                        {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Typography className='labels'>Select Role</Typography>
              <FormControl
                fullWidth
                margin="dense"
                error={!!formErrors.userRole}
                className='createpagetextfields'
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '15px',
                    '&:hover': {
                      borderColor: '#0D6EFD',

                    },
                    '& .MuiSelect-icon': { color: '#0D6EFD' },
                    '&.Mui-focused': {
                      borderColor: '#0D6EFD',
                      '& fieldset': {
                        borderColor: '#0D6EFD',
                      },
                    },
                  },
                }}
              >
                <Select
                  name="userRole"
                  displayEmpty
                  value={formValues.userRole}
                  onChange={handleInputChange}
                >
                  <MenuItem value="rfp_writer">RFP Writer</MenuItem>
                  <MenuItem value="executive_person">Executive Person</MenuItem>
                </Select>
                <FormHelperText>{formErrors.userRole}</FormHelperText>
              </FormControl>
              <SubmitButton
                type="submit"
                fullWidth
                variant="contained"
                className="bg-primary text-capitalize rounded-4 mt-2 mb-2"
                sx={{ padding: '9px' }}
                disabled={loading}

              >
                {loading ? <CircularProgress size={24} className=" text-primary" /> : 'Create New Account'}
              </SubmitButton>
              <Typography
                variant="body2"
                align="center"

              >
                <span className="fontstylee text-secondary">
                  Already have an account?
                </span>{' '}
                <Link className="fontstylee text-primary" to="/">
                  Log in
                </Link>
              </Typography>
            </Form>
          </div>
        </FormContainer>
      </Grid>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        sx={{ marginTop: '50px' }}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
      {loading && (
        <SpinnerContainer>
          <ClipLoader size={50} color="#123abc" loading={loading} />
        </SpinnerContainer>
      )}
    </Grid>
  );
};

export default Signup;