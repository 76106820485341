import React, { useState } from 'react';
import {
    Box,
    Button,
    Typography,
    Modal,
    IconButton,
    Divider,
    LinearProgress
} from '@mui/material';
import {
    Close as CloseIcon,
    CheckCircle as CheckCircleIcon,
    Cancel as CancelIcon,
    Pause as PauseIcon,
    PlayArrow as PlayArrowIcon,
} from '@mui/icons-material';
import PDFICON from '../../assets/Images/PDF.svg';
import './ContentLibrary.css';

const FileUploadModalUI = ({ open, onClose, setFileBase64, setFileName }) => {
    const [file, setFile] = useState(null);
    const [progress, setProgress] = useState(0);
    const [uploading, setUploading] = useState(false);
    const [uploadSuccess, setUploadSuccess] = useState(false);
    const [base64, setBase64] = useState('');


    const handleFileChange = (event) => {
        const uploadedFile = event.target.files[0];
        if (uploadedFile) {
            const validExtensions = ['.pdf'];
            const fileExtension = uploadedFile.name.substring(uploadedFile.name.lastIndexOf('.')).toLowerCase();

            if (!validExtensions.includes(fileExtension)) {
                alert('Invalid file type. Please upload a PDF, Word, or Excel file.');
                return;
            }

            setFile(uploadedFile);
            setProgress(0);
            setUploadSuccess(false);
            setUploading(true);

            const interval = setInterval(() => {
                setProgress((prevProgress) => {
                    if (prevProgress >= 100) {
                        clearInterval(interval);
                        setUploading(false);
                        setUploadSuccess(true);
                        return 100;
                    }
                    return prevProgress + 10;
                });
            }, 500);
        }
    };


    const handleCancelUpload = () => {
        setFile(null);
        setProgress(0);
        setUploading(false);
        setUploadSuccess(false);
        setBase64('');
    };

    const handleSubmit = () => {
        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                const base64Data = reader.result;
                setFileBase64(base64Data);
                setFileName(file.name);
                handleCancelUpload();
                onClose();
            };
            reader.readAsDataURL(file);
        }
    };


    return (
        <Modal open={open} onClose={onClose} aria-labelledby="modal-title" aria-describedby="modal-description">
            <Box className='fileuploadeditrfp' sx={{ ...style, width: '600px' }}>
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 5,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>

                <Typography
                    variant="h6"
                    component="h2"
                    textAlign="left"
                    className="fw-bold fs-6 pb-1"
                    sx={{ marginTop: '-13px' }}
                >
                    Add RFP
                </Typography>
                <Divider />
                <Typography variant="h5" component="h2" textAlign="center" className="fw-500 mt-2 fs-7">
                    Upload RFP document
                </Typography>

                <Box
                    sx={{
                        backgroundColor: 'rgba(215, 233, 245)',
                        border: '1px dashed blue',
                        borderRadius: '5px',
                        textAlign: 'center',
                        p: 5,
                        mt: 1,
                        mb: 2,
                        position: 'relative',
                        width: '93%',
                        ml: 2,
                    }}
                >
                    <Box onClick={() => document.getElementById('file-input').click()} style={{ cursor: 'pointer' }}>
                        <img src={PDFICON} alt="PDF Icon" width="70px" height="84px" />
                    </Box>

                    <Typography  style={{ color: '#0175FF', textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>
                        {uploading && <>Uploading {file?.name}...</>}
                        {uploadSuccess && <>{file?.name} uploaded successfully</>}
                    </Typography>

                    {!uploading && !uploadSuccess && !file && (
                        <>
                            Drag and Drop your File here, or{' '}
                            <label htmlFor="file-input" style={{ color: '#007bff', cursor: 'pointer' }}>
                                Browse Files
                            </label>
                            <Typography sx={{ color: '#888', fontSize: '12px' }}>
                                Limit 200MB per file • EXCEL, WORD, PDF Files Only
                            </Typography>
                        </>
                    )}
                    <input
                        accept=".pdf"
                        style={{ display: 'none' }}
                        id="file-input"
                        type="file"
                        onChange={handleFileChange}
                    />
                </Box>

                {file && (
                    <Box sx={{ width: '93%', marginLeft: '15px' }}>
                        <div className="border border-1px solid grey p-2">
                            <Box display="flex" alignItems="center" justifyContent="space-between">
                                <Box display="flex" alignItems="center">
                                    <img
                                        src={PDFICON}
                                        alt="PDF Icon"
                                        width="30px"
                                        height="42px"
                                        className="pb-2 mx-1"
                                    />
                                    <div style={{ maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                        <Typography style={{ color: '#0175FF', textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>{file.name} ({(file.size / 1024).toFixed(2)} KB)</Typography>
                                    </div>
                                </Box>
                                <Box display="flex" alignItems="center">
                                    {uploading ? (
                                        <IconButton onClick={handleCancelUpload}>
                                            <CancelIcon color="error" />
                                        </IconButton>
                                    ) : (
                                        uploadSuccess && (
                                            <IconButton>
                                                <CheckCircleIcon style={{ color: '#4caf50' }} />
                                            </IconButton>
                                        )
                                    )}
                                </Box>
                            </Box>
                            <LinearProgress
                                variant="determinate"
                                value={progress}
                                sx={{
                                    '& .MuiLinearProgress-bar': {
                                        backgroundColor: '#4caf50',
                                    },
                                    mt: 1,
                                }}
                            />
                        </div>
                    </Box>
                )}

                <Box display="flex" justifyContent="flex-end" mt={2}>
                    {progress === 100 && (
                        <div>
                            <Button onClick={handleCancelUpload} className="rounded-2 text-primary border border-primary mx-2">
                                Cancel
                            </Button>
                            <Button onClick={handleSubmit} className="rounded-2 bg-primary text-white">
                                Submit
                            </Button>
                        </div>
                    )}
                </Box>
            </Box>
        </Modal>
    );
};


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: '13px',
};

export default FileUploadModalUI;
