import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Card, Typography, TextField, Divider, Paper, IconButton, CardContent, LinearProgress, FormControl, Select, MenuItem } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { Row, Col } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Modal from '@mui/material/Modal';
import CommentsImage from '../../assets/Images/CommentsImage.svg';
import MicrosoftRFP from './ExtractRfpDocuments/MicrosoftRFP';
import PDFICON from '../../assets/Images/PDF.svg'
import CircularProgress from '@mui/material/CircularProgress';
import Badge from '@mui/material/Badge';
import NotificationsIcon from '@mui/icons-material/Notifications';
import axios from 'axios';
import './Dashboard.css'
import Trash_Empty from '../../assets/Images/Trash_Empty.svg';
import ExtractIMG from '../../assets/Images/extract.svg';
import EYEIMG from '../../assets/Images/eye.svg';
import NotificationsPanel from './Notifications';
import { useSnackbar } from '../SnackbarContext';
import Backdrop from '@mui/material/Backdrop';
import GlobalVariable from "../../services/Global";

const baseApiUrl = GlobalVariable.BASE_API_URL;

const style = {
    position: 'absolute',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 3,
    borderRadius: 4,
    justifyContent: "center",
    alignItems: "center",
    direction: "column",
    width: "90%",
    margin: 10
};

const styled = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 650,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 3,
    borderRadius: 4,
};

function RfpPreview() {
    const location = useLocation();
    const navigate = useNavigate();
    const { showSnackbar } = useSnackbar();
    const { id } = useParams();
    const [open, setOpen] = useState(false);
    const [fetchLoading, setFetchLoading] = useState(false);
    const [selectedDocument, setSelectedDocument] = useState(null)
    const [fileUploadModalOpen, setFileUploadModalOpen] = useState(false)
    const [file, setFile] = useState(null);
    const [progress, setProgress] = useState(0);
    const [uploading, setUploading] = useState(false);
    const [uploadSuccess, setUploadSuccess] = useState(false);
    const [paused, setPaused] = useState(false);
    const [resumeRequested, setResumeRequested] = useState(false);
    const uploadTimer = useRef(null);
    const [documentType, setDocumentType] = useState('')
    const [fileName, setFileName] = useState('');
    const [comment, setComment] = useState()
    const [remark, setRemark] = useState()
    const [rowData, setRowData] = useState({});
    const [Commentcount, setCommentcount] = useState([]);
    const [remarkcount, setRemarkcount] = useState([]);
    const [documents, setDocuments] = useState([]);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [documentToDelete, setDocumentToDelete] = useState(null);
    const [loading, setLoading] = useState(false);
    const [showMicrosoftRFP, setShowMicrosoftRFP] = useState(false);
    const [isCloseDisabled, setIsCloseDisabled] = useState(false);
    const [docResponse, setDocResponse] = useState('')
    const [isNotificationsOpen, setNotificationsOpen] = useState(false);
    const [unreadCount, setUnreadCount] = useState(0);
    const [specDocId, setSpecDocId] = useState("");
    const [addDocLoading, setAddDocLoading] = useState(false)

    const statusMapping = {
        1: 'New',
        4: 'Submitted',
        3: "On Hold",
        2: 'In Progress'
    }

    const storedData = JSON.parse(localStorage.getItem('userDetails'));
    const userName = storedData.userDetails.userName;

    const fetchDocuments = async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${baseApiUrl}/dashboard/listdocumnets/get_all_docs`, {
                params: { rfpid: id }
            });

            if (response.status === 200) {
                setDocuments(response.data.results);
            } else {
                console.error("Failed to fetch documents");
            }
        } catch (error) {
            console.error("Error fetching documents:", error);
        } finally {
            setLoading(false);
        }
    };



    const fetchrfpData = () => {
        setFetchLoading(true);
        axios.get(`${baseApiUrl}/dashboard/fetchrfp?id=${id}`)
            .then(response => {
                setRowData(response.data.details);
                setCommentcount(response.data.details.comments);
                setRemarkcount(response.data.details.remarks);
                setFetchLoading(false);
            })
            .catch(error => {
                console.error('Error fetching RFP details:', error);
                setFetchLoading(false);
            });
    };

    const handleDocumentClick = (document) => {
        setLoading(true);
        axios.get(`${baseApiUrl}/doc_extract/get_pdf`, {
            params: { doc_id: document.doc_id }
        })
            .then((response) => {
                setDocResponse(response.data.results)
                setSelectedDocument(document);
                setShowMicrosoftRFP(true);
                setLoading(false);
                setSpecDocId(document?.doc_id)


            })
            .catch((error) => {
                console.error('Error fetching document:', error);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    useEffect(() => {
        fetchrfpData();
        fetchDocuments();
    }, []);


    const handleDeleteDocument = async () => {
        setLoading(true);
        try {
            const response = await axios.delete(`${baseApiUrl}/dashboard/listdocumnets/del_doc`, {
                data: {
                    rfpid: id,
                    doc_name: documentToDelete.doc_name
                }
            });
            if (response.data.status_code === 200) {
                showSnackbar("Document deleted successfully", "success");
                fetchDocuments();
                handleCloseDeleteModal();
                setLoading(false);
            }

        } catch (error) {
            console.error("Delete failed:", error.response.data.message);
            setLoading(false);
        }
    };

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);

    };

    const handleOpenDeleteModal = (doc) => {
        setDocumentToDelete(doc);
        setDeleteModalOpen(true);
    };
    const handleCloseDeleteModal = () => {
        setDeleteModalOpen(false);
        setDocumentToDelete(null);
    };

    const handleBackdetails = () => {
        navigate('/dashboard');
    };
    const pdfOpenDeatils = (docUrl) => {
        navigate(`/view-document/${encodeURIComponent(docUrl)}`);
    }


    const handleBackButtonClick = () => {
        setShowMicrosoftRFP(false);
    };

    const handleEditPage = () => {
        navigate(`/MicrosoftRFPDetailsEditPage/${id}`, { state: { formData: rowData } })
    }


    const simulateFileUpload = useCallback((currentProgress = 0) => {
        uploadTimer.current = setInterval(() => {
            if (currentProgress < 90) {
                currentProgress += 5;
                setProgress(currentProgress);
            }
        }, 500);
    }, []);

    const handleFileChange = async (event) => {
        const uploadedFile = event.target.files[0];
        const fileSizeInMB = uploadedFile.size / (1024 * 1024);
        if (fileSizeInMB > 3) {
            showSnackbar('File size exceeds greater than 3MB. Please upload a smaller file.', 'error');
            event.target.value = null;
        } else {
            if (uploadedFile) {
                simulateFileUpload();
                const base64 = await convertToBase64(uploadedFile);
                setFile({
                    ...uploadedFile,
                    base64,
                    readableSize: formatFileSize(uploadedFile.size)
                });
                setFileName(uploadedFile.name);
            }
        };
    }

    const formatFileSize = (size) => {
        const i = Math.floor(Math.log(size) / Math.log(1024));
        return `${(size / Math.pow(1024, i)).toFixed(2)} ${['B', 'KB', 'MB', 'GB', 'TB'][i]}`;
    };

    const handleFileUpload = async () => {
            setAddDocLoading(true);
            setUploading(true);
            setIsCloseDisabled(true);
            axios.post(`${baseApiUrl}/dashboard/listdocumnets/add_doc`, {
                rfpid: id,
                doc_name: fileName,
                doc_type: documentType,
                base64: file.base64,
            })
            .then((res) => {

            if (res.data.status_code === 200) {
                setFileUploadModalOpen(false);
                fetchDocuments();
                handleFileClose();
                setProgress(100);
                showSnackbar('Document added successfully.', 'success');
            }
        })
        .catch((error) => {
            console.error("Upload failed:", error.response);
            setProgress(0);
            if (error.status === 500) {
                showSnackbar('This File name not supported. Please change file name and upload it.', 'error');
                setFile(null);
                setFileName("");
                setDocumentType("");
              }
        }) 
        .finally(() => {
            setAddDocLoading(false);
            setUploading(false);
            clearInterval(uploadTimer.current);
            setIsCloseDisabled(false);
        })
    }

    const handleCancelUpload = () => {
        cleanUp();
        setProgress(0);
        setFile(null);
        setUploadSuccess(false);
        document.getElementById('raised-button-file').value = '';
    };

    const handlePauseUpload = () => {
        setPaused(true);
        setUploading(false);
        clearInterval(uploadTimer.current);
    };

    const handleResumeUpload = () => {
        if (paused) {
            setPaused(false);
            setResumeRequested(true);
        }
    };

    useEffect(() => {
        if (resumeRequested && !uploading) {
            setResumeRequested(false);
            simulateFileUpload(file, progress);
        }
    }, [resumeRequested, file, progress, uploading, simulateFileUpload]);

    const cleanUp = () => {
        clearInterval(uploadTimer.current);
        setUploading(false);
        setPaused(false);
    };

    const handleFileOpen = () => setFileUploadModalOpen(true);

    const handleFileClose = () => {
        cleanUp();
        setFile(null);
        setFileName('');
        setDocumentType('');
        setProgress(0);
        setUploadSuccess(false);
        setLoading(false);
        document.getElementById('raised-button-file').value = '';
        setFileUploadModalOpen(false);
    };


    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result.split(',')[1]);
            reader.onerror = error => reject(error);
        });
    };


    const handleFileNameChange = (event) => {
        setFileName(event.target.value);
    };

    const handleNotificationsOpen = () => {
        setNotificationsOpen(true);
        setUnreadCount(0);
    };

    const handleNotificationsClose = () => {
        setNotificationsOpen(false);
    };

    const handleSaveComment = () => {
        const storedData = JSON.parse(localStorage.getItem('userDetails'));
        const userId = storedData.userDetails.id;
        const commentData = {
            userid: userId,
            comments: comment,
            rfpid: id,
            status: ''
        }
        axios.post(`${baseApiUrl}/dashboard/addrfpcomment`, commentData)
            .then(response => {
                fetchrfpData();
                setComment('');
                showSnackbar('comment added successfully.', 'success');
            })
            .catch(error => {
                console.error('Error saving comment:', error.response.data);
            });
    }

    useEffect(() => {
        const storedData = JSON.parse(localStorage.getItem('userDetails'));
        const userId = storedData.userDetails.id;
        axios
            .get(`${baseApiUrl}/notification/getlist?rfpid=${id}&userid=${userId}`)
            .then((response) => {
                const data = response.data;
                const newNotifications = [
                    { type: 'Conference Due', text: data.conference_notify, read: false },
                    { type: 'RFP Questions Due', text: data.questionnaire_notify, read: false },
                    { type: 'Proposal Due', text: data.submission_notify, read: false },
                ];

                setUnreadCount(newNotifications.length);
            })
            .catch((error) => {
                console.error('Error fetching notifications:', error);
            });
    }, []);



    return (
        <Container fluid className='ps-3 mt-2 rfpDtailsContainer '>
            {loading && (
                <Box
                    className="circular-progress"
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        zIndex: 1000,
                    }}
                >
                    <CircularProgress className=" text-primary" />
                </Box>
            )}
            <div >
                <Card className='rounded-3'>
                    <Box style={{ paddingBottom: '16px' }}>
                        <div style={{ padding: '16px 0' }}>
                            <Row className="mb-2 align-items-center mx-1">
                                <Col xs={12} sm={6} md={6}>
                                    <Typography className='fontstylee MicroSoftRFPDetails_heading' sx={{ width: 'auto', whiteSpace: 'nowrap' }}>
                                        MicroSoft RFP Details
                                    </Typography>
                                </Col>
                                <Col xs={12} sm={6} md={6} className=" d-flex justify-content-end">
                                    <Button className='border-primary text-primary text-capitalize rounded-2 '
                                        variant="outlined"
                                        color="primary"
                                        onClick={handleBackdetails}
                                    >
                                        Back
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                        <Container fluid>
                            <Box
                                sx={{
                                    border: '1px solid #ccc',
                                    borderRadius: '10px',
                                    padding: '16px',
                                    position: 'relative',
                                    marginTop: "6px"
                                }}
                                className='mb-5'
                            >
                                <Typography
                                    sx={{
                                        position: 'absolute',
                                        top: '-12px',
                                        left: '20px',
                                        background: '#fff',
                                        padding: '0 8px',
                                        fontSize: '16px'
                                    }}
                                    className='fontstylee'
                                >
                                    RFP Details
                                </Typography>
                                <Row className=' pb-4 pt-2'>
                                    <Col lg={3} md={4} sm={6} >
                                        <Typography className='fontstylee py-3 search-bar' >First Review</Typography>
                                        <Typography>{rowData.first_review}</Typography>

                                    </Col>
                                    <Col lg={3} md={4} sm={6}>
                                        <Typography className='fontstylee py-3'>RFP Issuer</Typography>
                                        <Typography>{rowData.rfp_issuer}</Typography>
                                    </Col>
                                    <Col lg={3} md={4} sm={6}>
                                        <Typography className='fontstylee py-3'>Rfp Type</Typography>
                                        <Typography>{rowData.rfp_type}</Typography>
                                    </Col>
                                    <Col lg={3} md={12} sm={6}>
                                        <Typography className='fontstylee py-3'>Location</Typography>
                                        <Typography>{rowData.location}</Typography>
                                    </Col>
                                </Row>
                                <Row>

                                    <Col lg={3} md={6} sm={3}>
                                        <Typography className='fontstylee py-3'>Budget Range</Typography>
                                        <Typography>{rowData.budget_range}</Typography>
                                    </Col>
                                    <Col lg={3} md={6} sm={3}>
                                        <Typography className='fontstylee py-3'>Expected Timeline</Typography>
                                        <Typography>{rowData.expected_timeline + ' months'}</Typography>
                                    </Col>
                                    <Col xs={12} lg={4} md={12} sm={3} >
                                        <Typography className='fontstylee py-3' >Website</Typography>

                                        <Typography sx={{
                                            wordBreak: 'break-all'
                                        }} >      <a href={rowData.website} target="_blank" rel="noopener noreferrer">
                                                {rowData.website}
                                            </a></Typography>

                                    </Col>
                                </Row>
                            </Box>
                            <Box
                                sx={{
                                    border: '1px solid #ccc',
                                    borderRadius: '10px',
                                    padding: '16px',
                                    position: 'relative'
                                }}
                                className='mb-5'
                            >
                                <Typography
                                    sx={{
                                        position: 'absolute',
                                        top: '-12px',
                                        left: '20px',
                                        background: '#fff',
                                        padding: '0 8px',
                                        fontSize: '16px'
                                    }}
                                    className='fontstylee microsoftrfpdetails'
                                >
                                    Point of Contact Details
                                </Typography>
                                <Row>
                                    <Col lg={4} md={5} sm={3}>
                                        <Typography className='fontstylee py-3'>Name</Typography>
                                        <Typography className='text-capitalize'>{rowData.contact_name}</Typography>
                                    </Col>
                                    <Col lg={5} md={5} sm={3}>
                                        <Typography className='fontstylee py-3'>Email</Typography>
                                        <Typography sx={{
                                            wordBreak: 'break-all'
                                        }}>{rowData.contact_email}</Typography>
                                    </Col>
                                </Row>
                            </Box>
                            <Box
                                sx={{
                                    border: '1px solid #ccc',
                                    borderRadius: '10px',
                                    padding: '16px',
                                    marginBottom: '16px',
                                    position: 'relative'
                                }}
                            >
                                <Typography
                                    className='fontstylee'
                                    sx={{
                                        position: 'absolute',
                                        top: '-12px',
                                        left: '20px',
                                        background: '#fff',
                                        padding: '0 8px',
                                        fontSize: '16px'

                                    }}
                                >
                                    Submission Details
                                </Typography>
                                <Row className='pb-4'>
                                    <Col lg={3} md={6} sm={6}>
                                        <Typography className='fontstylee py-3'>Conference Date</Typography>
                                        <Typography>{rowData.conference_date}</Typography>
                                    </Col>
                                    <Col lg={3} md={6} sm={6}>
                                        <Typography className='fontstylee py-3'>Conference Information</Typography>
                                        <Typography>{rowData.conference_information}</Typography>
                                    </Col>
                                    <Col lg={3} md={6} sm={6}>
                                        <Typography className='fontstylee py-3'>Questionnaire Date</Typography>
                                        <Typography>{rowData.questionnaire_date}</Typography>
                                    </Col>
                                    <Col lg={3} md={6} sm={6}>
                                        <Typography className='fontstylee py-3'>Status</Typography>
                                        <Typography>{statusMapping[rowData.status]}</Typography>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={3} md={6} sm={6}>
                                        <Typography className='fontstylee py-3'>Submission Mode</Typography>
                                        <Typography>{rowData.submission_mode}</Typography>
                                    </Col>
                                    <Col lg={3} md={6} sm={6}>
                                        <Typography className='fontstylee py-3'>Submission Date</Typography>
                                        <Typography>{rowData.submission_date}</Typography>
                                    </Col>
                                </Row>
                            </Box>
                            <Box


                            >

                                <>
                                    <Row className='py-3 g-2 rfpdetailscn'>
                                        <Col xs={12} sm={12} lg={3} md={5} className='d-flex align-items-center ps-2 rfpdetailscnn commentsrfpd '>
                                            <img src={CommentsImage} alt='commentsImage' className='me-2 iconheight' />
                                            <Typography
                                                variant='h5'
                                                onClick={handleOpen}
                                                className='fontstylee previewCommentText text-primary'
                                            >
                                                Comments({Commentcount.length})
                                            </Typography>
                                        </Col>

                                        <Col xs={12} sm={12} lg={2} md={4} className='d-flex align-items-center rfpdetailscnbn '>
                                        <div className='badgenotificationmd'>
                                            <Badge
                                                color="error"
                                                badgeContent={unreadCount}
                                                onClick={handleNotificationsOpen}
                                                className="cursor-pointer"
                                                sx={{
                                                    '& .MuiBadge-badge': { backgroundColor: unreadCount > 0 ? 'red' : 'blue' }
                                                }}
                                            >
                                                <NotificationsIcon fontSize="large" className='text-primary notificationicon' />
                                            </Badge>
                                            </div>

                                            <Typography
                                                variant='h5'
                                                onClick={handleNotificationsOpen}
                                                className='fontstylee previewRemarkText text-primary cursor-pointer'
                                            >
                                                Notifications
                                            </Typography>
                                        </Col>

                                        <Col lg={7} md={3} className='d-flex justify-content-end pe-2'>
                                            <Button
                                                variant="contained"
                                                className='bg-primary text-capitalize rounded-2 docUpload_editbtn '
                                                onClick={handleEditPage}
                                            >
                                                Edit
                                            </Button>
                                        </Col>
                                    </Row>

                                    <NotificationsPanel open={isNotificationsOpen} onClose={handleNotificationsClose} rfpId={id} />
                                </>


                            </Box>
                        </Container>
                    </Box>
                </Card>

                <Card className='mt-3' sx={{ height: 420 }}>
                    <CardContent sx={{ height: '100%' }}>

                        <Row className="mb-2 align-items-center">
                            <Col xs={12} sm={6} md={6}>
                                <Typography className='fontstylee MicroSoftRFPDetails_heading'>
                                    List of Documents
                                </Typography>
                            </Col>
                            <Col xs={12} sm={6} md={6} className=" d-flex justify-content-end">
                                <Button className='bg-primary text-capitalize rounded-2 '
                                    variant="contained"
                                    color="primary"
                                    onClick={handleFileOpen}
                                >
                                    Add
                                </Button>
                            </Col>
                        </Row>
                        <Card>
                            <TableContainer sx={{
                                width: '100%',
                                height: '335px',
                                overflow: 'auto',
                                '&::-webkit-scrollbar': {
                                    height: '3px',
                                    width: '3px',
                                },
                                '&::-webkit-scrollbar-thumb': {
                                    backgroundColor: '#888',
                                    borderRadius: '6px',
                                },
                                '&::-webkit-scrollbar-thumb:hover': {
                                    backgroundColor: '#555',
                                    cursor: 'pointer',
                                },
                            }}>
                                <Table aria-label="simple table" stickyHeader sx={{ width: '100%' }}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{ width: 'auto', whiteSpace: 'nowrap' }}><Typography className='fontstylee'>Document Name</Typography></TableCell>
                                            <TableCell sx={{ width: 'auto', whiteSpace: 'nowrap' }}><Typography className='fontstylee'>Document Type</Typography></TableCell>
                                            <TableCell sx={{ width: 'auto', whiteSpace: 'nowrap' }}><Typography className='fontstylee'>Uploaded Date</Typography></TableCell>
                                            <TableCell sx={{ width: 'auto', whiteSpace: 'nowrap' }}><Typography className='fontstylee'>Actions</Typography></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody className='position-relative'>

                                        {documents.length === 0 && !loading ? (
                                            <Box
                                                sx={{

                                                    position: 'absolute',
                                                    top: 100,
                                                    left: 450,
                                                }}
                                            >
                                                <Typography variant="h6">No Records Found</Typography>
                                            </Box>
                                        ) : (
                                            documents.map((doc, index) => (
                                                <TableRow key={index}>
                                                    <TableCell sx={{ width: 'auto', whiteSpace: 'nowrap' }}>
                                                        <Typography
                                                            onClick={() => handleDocumentClick(doc)}
                                                            className='fontstylee text-primary documentname'

                                                        >
                                                            {doc.doc_name}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ width: 'auto', whiteSpace: 'nowrap' }}>{doc.doc_type}</TableCell>
                                                    <TableCell sx={{ width: 'auto', whiteSpace: 'nowrap' }}>{doc.uploaded_on}</TableCell>
                                                    <TableCell sx={{ width: 'auto', whiteSpace: 'nowrap' }}>
                                                        <IconButton onClick={() => pdfOpenDeatils(doc.doc_url)}>
                                                            <img src={EYEIMG} width="31px" height="31px" alt="eyeimg" style={{ backgroundColor: '#D9EAFF', borderRadius: '38%', padding: '7px' }} />
                                                        </IconButton>
                                                        <IconButton onClick={() => handleOpenDeleteModal(doc)}>
                                                            <img src={Trash_Empty} width="31px" height="31px" alt="trshimg" style={{ backgroundColor: '#FCD9D9', borderRadius: '38%', padding: '7px' }} />
                                                        </IconButton>

                                                        <IconButton onClick={() => handleDocumentClick(doc)}>
                                                            <img src={ExtractIMG} width="31px" height="31px" alt="extractimg" style={{ backgroundColor: '#DCF1E4', borderRadius: '38%', padding: '7px' }} />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Card>
                    </CardContent>
                </Card>
                <div className='mt-3'>
                    {showMicrosoftRFP && (
                        <Card className="mt-3">
                            <MicrosoftRFP
                                document={selectedDocument}
                                documentResponse={docResponse}
                                onBackButtonClick={handleBackButtonClick}
                                id={specDocId}
                            />
                        </Card>
                    )}
                </div>

                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"

                >
                    <FormControl fullWidth margin="normal" className="inputfields">
                        <Box className="commentsboxeditrfp" sx={style}>

                            <Box display="flex" justifyContent="space-between" alignItems="baseline" >
                                <Typography gutterBottom className='commentsText inputfields'>
                                    Comments
                                </Typography>
                                <CloseIcon onClick={handleClose} style={{ cursor: 'pointer' }} />
                            </Box>
                            <Divider style={{ borderColor: '#adadad' }} />
                            <Typography id="modal-modal-description" sx={{ mt: 2 }} className='addCommentText'>
                                Add Comments
                            </Typography>
                            <TextField
                                fullWidth
                                label="Comment"
                                variant="outlined"
                                className=' mb-2 rounded-5 mt-3'
                                multiline
                                rows={2}
                                value={comment}
                                onChange={(e) => setComment(e.target.value)}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: '16px',
                                    },
                                }}
                            />
                            <Button variant="contained" className="mb-3 float-end bg-primary rounded-5 " onClick={handleSaveComment}>
                                Save
                            </Button>
                            <Typography className='mt-4 fs-6' >Previous Comment list</Typography>
                            <TableContainer component={Paper} sx={{
                                maxHeight: '300px',
                                overflowY: 'auto',
                                borderColor: 'ActiveBorder',
                                borderRadius: '12px',
                                marginTop: '15px'
                            }}>
                                <Table stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left" className='remarkstext'>Comment</TableCell>
                                            <TableCell align="left" className='remarkstext'>Commented Date</TableCell>
                                            <TableCell align="left" className='remarkstext'>Comment By</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {Commentcount.map((comment, index) => (
                                            <TableRow key={comment.id}>
                                                <TableCell align="left">{comment.comments}</TableCell>
                                                <TableCell align="left">{comment.comment_date}</TableCell>
                                                <TableCell align="left">{comment.comment_by}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </FormControl>
                </Modal>

                <Modal open={fileUploadModalOpen} onClose={handleFileClose} aria-labelledby="modal-title" aria-describedby="modal-description">
                    <Box sx={{ ...styled, width: '550px' }} className='docuploadModal'>
                    {addDocLoading && (
                <Box
                    className="circular-progress"
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        zIndex: 1000,
                    }}
                >
                    <CircularProgress className=" text-primary" />
                </Box>
            )}

                        <IconButton
                            aria-label="close"
                            onClick={handleFileClose}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 5,
                                color: (theme) => theme.palette.grey[500],
                            }}
                            disabled={isCloseDisabled}
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" component="h2" textAlign="left" className='fontstyleeb fs-6 pb-1' sx={{ marginTop: '-13px' }}>
                            Add New Document
                        </Typography>
                        <Divider />
                        <Row>
                            <Col lg={7} sm={6} xs={6}>
                                <FormControl fullWidth margin="normal" className='inputFields inputfields'>
                                    <Typography className='docName'>Document Name</Typography>
                                    <TextField
                                        placeholder="Document Name"
                                        name="DocName"
                                        className='inputFields'
                                        value={fileName}
                                        onChange={handleFileNameChange}
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                borderRadius: '13px',
                                            },
                                        }}
                                    />
                                </FormControl>
                            </Col>
                            <Col lg={5} sm={6} xs={6}>
                                <FormControl fullWidth margin="normal" className='inputFields inputfields'>
                                    <Typography className='docName'>Document Type</Typography>
                                    <Select
                                        value={documentType}
                                        onChange={(e) => setDocumentType(e.target.value)}
                                        name="submissionMode"
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                borderRadius: '10px',
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                borderRadius: '10px',
                                            },
                                            '& .MuiSelect-icon': {
                                                color: 'blue',
                                            },
                                        }}
                                    >
                                        <MenuItem value="Addendum">Addendum</MenuItem>
                                        <MenuItem value="Support">Support</MenuItem>
                                        <MenuItem value="Final/Response">Final/Response</MenuItem>
                                        <MenuItem value="RFP">RFP</MenuItem>
                                    </Select>
                                </FormControl>
                            </Col>
                        </Row>
                        <Box
                            sx={{
                                backgroundColor: 'rgba(215, 233, 245)',
                                border: '1px dashed blue',
                                borderRadius: '5px',
                                textAlign: 'center',
                                p: 6,
                                mt: 1,
                                mb: 2,
                                position: 'relative',
                                width: '100%',
                            }}
                            className='docuploadBox'
                        >
                            <Box onClick={() => document.getElementById('raised-button-file').click()}>
                                <img src={PDFICON} alt='PDFICON' width={'70px'} height={'84px'} style={{ cursor: 'pointer' }} className='addRfpdoc' />
                            </Box>

                            {!file && (
                                <>
                                    <Typography className='dragText'>Drag and Drop your File here, or{' '}
                                        <label htmlFor="raised-button-file" style={{ color: '#007bff', cursor: 'pointer' }}>
                                            Browse Files
                                        </label>
                                    </Typography>
                                    <Typography sx={{ color: '#888', fontSize: '12px' }} className='dragText'>
                                        Limit 200MB per file • PDF Files Only
                                    </Typography>
                                </>
                            )}

                            {file && (
                                <>
                                    <Typography sx={{ fontSize: '14px', mt: 1 }} className='uploadDocName' style={{ textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>
                                        {fileName || file.name}
                                    </Typography>
                                    <Typography sx={{ color: '#888', fontSize: '12px' }} className='uploadDocName'>
                                        ({file.readableSize})
                                    </Typography>
                                </>
                            )}

                            <input
                                accept=".pdf"
                                style={{ display: 'none' }}
                                id="raised-button-file"
                                type="file"
                                onChange={handleFileChange}
                            />
                        </Box>
                        {uploading && (
                            <Box sx={{ width: '100%' }}>
                                <div className='border border-1px solid grey p-2'>
                                    <Box display="flex" alignItems="center" justifyContent="space-between">
                                        <Box display="flex" alignItems="center">
                                            <img src={PDFICON} alt='PDFICON' width={'30px'} height={'42px'} style={{ cursor: 'pointer' }} className='pb-2 mx-1' />
                                            <div style={{ maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                <Typography className='uploadDocName' style={{ textDecoration: 'none', textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>
                                                    {fileName || file.name}
                                                    {file?.readableSize || 'Size unknown'}
                                                </Typography>
                                            </div>
                                        </Box>
                                    </Box>
                                    <LinearProgress variant="determinate" value={progress} sx={{
                                        '& .MuiLinearProgress-bar': {
                                            backgroundColor: '#4caf50',
                                        },
                                        mt: 1,
                                    }} />
                                </div>
                            </Box>
                        )}
                        <Box display="flex" justifyContent="flex-end" mt={2}>
                            <div>
                                {file && fileName && documentType && (
                                    <Button color="primary" onClick={handleFileUpload} className='rounded-2 bg-primary text-white' disabled={addDocLoading}>
                                        Save
                                    </Button>
                                )}
                            </div>
                        </Box>
                    </Box>
                </Modal>

                <Modal
                    open={deleteModalOpen}
                    onClose={handleCloseDeleteModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={{ ...styled, width: '500px' }}>
                        <Box display="flex" justifyContent="space-between" alignItems="baseline" >
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                Confirm Delete
                            </Typography>
                            <CloseIcon onClick={handleCloseDeleteModal} style={{ cursor: 'pointer' }} />
                        </Box>
                        <Divider style={{ borderColor: '#adadad' }} />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            Are you sure you want to delete "{documentToDelete?.doc_name}"?
                        </Typography>
                        <Box mt={4} display="flex" justifyContent="end" >
                            <Button variant="outlined" className='border-primary text-primary rounded-3' onClick={handleCloseDeleteModal}>
                                Cancel
                            </Button>
                            <Button variant="contained" className='bg-primary mx-2 rounded-3' onClick={handleDeleteDocument}>
                                Delete
                            </Button>
                        </Box>
                    </Box>
                </Modal>
            </div>
            <Backdrop
                sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
                open={fetchLoading}
            >
                <CircularProgress className=' text-primary' />
            </Backdrop>
        </Container>
    );
}
export default RfpPreview;