import React, { useState, useEffect } from 'react';
import { Container, Typography, Paper, FormControl, Button, Box, TextField, Snackbar, Alert, Modal, IconButton, Card, CircularProgress } from '@mui/material';
import { Close as CloseIcon, Delete, Edit } from '@mui/icons-material';
import PDF from '../../assets/Images/PDF.svg';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { AttachFile } from '@mui/icons-material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import axios from 'axios';
import EditFileUpload from './EditFileUpload';
import { useSnackbar } from '../SnackbarContext';
import GlobalVariable from "../../services/Global";

const baseApiUrl = GlobalVariable.BASE_API_URL;

const CustomToolbar = ({ handleAttachFileClick }) => (
  <div id="toolbar">
    <select className="ql-font">
      <option value="sans-serif" selected>
        Sans Serif
      </option>
      <option value="serif">Serif</option>
      <option value="monospace">Monospace</option>
    </select>
    <select className="ql-header">
      <option value="1">Heading 1</option>
      <option value="2">Heading 2</option>
      <option value="">Normal</option>
    </select>
    <button className="ql-bold"></button>
    <button className="ql-italic"></button>
    <button className="ql-underline"></button>
    <button className="ql-strike"></button>
    <button className="ql-list" value="ordered"></button>
    <button className="ql-list" value="bullet"></button>
    <select className="ql-align"></select>
    <button className="ql-link"></button>
    <button className="ql-image"></button>
    <button onClick={handleAttachFileClick}>
      <AttachFile style={{ fontSize: 18 }} />
    </button>
  </div>
);

const modules = {
  toolbar: {
    container: '#toolbar',
  },
};

const formats = [
  'font',
  'header',
  'bold', 'italic', 'underline', 'strike',
  'list', 'bullet',
  'align',
  'link', 'image',
];

const EditSectionDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [fileBase64, setFileBase64] = useState('');
  const [fileName, setFileName] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [sectionName, setSectionName] = useState('');
  const [description, setDescription] = useState('');
  const [attachment, setAttachments] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const { showSnackbar } = useSnackbar();
  const [openDeleteSnackbar, setOpenDeleteSnackbar] = useState(false);
  const [isPdfOpen, setPdfOpen] = useState(false);
  const [errors, setErrors] = useState({ sectionName: '', description: '' });
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    const { sectionName, description, attachment, filename } = location.state || {};
    setSectionName(sectionName || '');
    setDescription(description || '');
    setAttachments(attachment || fileBase64);
    setFileName(filename || fileName);
  }, [location.state]);


  const validate = () => {
    let isValid = true;
    const newErrors = { sectionName: '', description: '' };

    if (!sectionName.trim()) {
      newErrors.sectionName = 'Section Name is required';
      isValid = false;
    } else if (sectionName.length < 3) {
      newErrors.sectionName = 'Section Name must be at least 3 characters long';
      isValid = false;
    } else if (!description.trim() || description === '' || description === '<p><br></p>') {
      newErrors.description = 'Description is required';
      isValid = false;
    } else if (description.length < 10) {
      newErrors.description = 'Description must be at least 10 characters long';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };
  const handleSectionNameChange = (e) => {
    setSectionName(e.target.value);
    if (errors.sectionName) {
      setErrors((prevErrors) => ({ ...prevErrors, sectionName: '' }));
    }
  };

  const handleDescriptionChange = (value) => {
    setDescription(value);
    if (errors.description) {
      setErrors((prevErrors) => ({ ...prevErrors, description: '' }));
    }
  };

  const handleUpdate = async () => {
    if (!validate()) return;
    setLoading(true);

    const storedData = JSON.parse(localStorage.getItem('userDetails'));
    const accessToken = storedData?.access_token;

    try {
      const response = await axios.put(`${baseApiUrl}/contentlibrary/updateclsection`,
        {
          id: id,
          content_name: sectionName,
          content_description: description,
          content_file: attachment,
          content_filename: fileName,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.status === 200) {
        showSnackbar('Data has been updated successfully');
        setOpenSnackbar(true);
        navigate(`/content-library`, {
          state: {
            row: {
              content_name: sectionName,
              content_description: description,
              content_file: attachment,
              content_filename: fileName
            }
          },
        });
      }

    } catch (err) {
      showSnackbar('Failed to update data');
    } finally {
      setLoading(false);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
    setOpenDeleteSnackbar(false);
  };

  const togglePdfView = () => {
    setPdfOpen(!isPdfOpen);
  };

  const handleAttachFileClick = () => {
    setOpenDialog(true);
  };
  const handleDeleteAttachment = () => {
    setAttachments('');
    setFileBase64('');
    setFileName('');
    setOpenDeleteSnackbar(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  return (
    <Container fluid className='rfpDtailsContainer' maxWidth="100%" sx={{ padding: { xs: 1, sm: 2, md: 3 } }}>
      <Box mt={2}>
        <Paper elevation={3} sx={{ padding: { xs: 2, sm: 3, md: 5 }, borderRadius: '15px' }}>
          <Box display="flex" justifyContent="space-between" flexDirection={{ xs: 'column', sm: 'row' }}>
            <Typography variant="h5" gutterBottom className='fontstyleeb'>Edit Section Details</Typography>
            <Button
              variant="outlined"
              onClick={() => navigate(-1)}
              className='buttonbackss Buttons_ESD1 border-primary text-primary text-capitalize rounded-3'
              sx={{ width: { xs: '100%', sm: 'auto' }, marginBottom: { xs: 2, sm: 0 } }}
            >
              Back
            </Button>
          </Box>

          <Box className='formesd'>
            <FormControl fullWidth margin="normal" className="inputfields">
              {loading && (
                <Box
                  className="circular-progress"
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    zIndex: 1000,
                  }}
                >
                  <CircularProgress className=" text-primary" />
                </Box>

              )}
              <Typography variant="h6" mt={2} mb={1} color="GrayText" className='fontstylee'>Section Name</Typography>
              <TextField
                value={sectionName}
                onChange={handleSectionNameChange}
                variant='outlined'
                fullWidth
                error={!!errors.sectionName}
                helperText={errors.sectionName}
                sx={{
                  '& .MuiOutlinedInput-root': { borderRadius: '20px' },
                  marginBottom: 3,
                  width: { xs: '100%', sm: '75%', md: '50%' },
                }}
              />
            </FormControl>
          </Box>

          <Box>
            <Typography variant="h6" mb={1} color="GrayText" className='fontstylee'>Description</Typography>
            <CustomToolbar handleAttachFileClick={handleAttachFileClick} />
            <ReactQuill
              modules={modules}
              formats={formats}
              value={description}
              onChange={handleDescriptionChange}
              style={{ height: '200px', marginBottom: '20px' }}
            />
            {errors.description && (
              <Typography color="error" variant="body2">
                {errors.description}
              </Typography>
            )}
          </Box>
          <Box mt={3}>
            {attachment ? (
              <Typography variant="h6" mt={2} mb={2} color="GrayText" gutterBottom>Attachment</Typography>
            ) : null}
          </Box>

          <Card sx={{ width: '200px', justifyItems: 'center' }} >
            {attachment ? (
              <>
                <Typography
                  variant="body1"
                  style={{ color: '#0175FF', margin: '10px', textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden", width: '100px' }}
                >
                  {fileName}
                </Typography>
                <img src={PDF} alt="PDF Icon" onClick={togglePdfView} style={{ cursor: 'pointer', color: '#0175FF' }} />
                <Typography
                  variant="body1"
                  onClick={togglePdfView}
                  style={{ cursor: 'pointer',color: '#0175FF', margin: '10px', textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden", width: '100px' }}
                >
                  View File
                </Typography>
                <Box display={'flex'} mt={3}>
                  <IconButton
                    onClick={handleAttachFileClick}
                  >
                    <Edit
                    className='edit-icon'
                    />
                  </IconButton>
                  <IconButton color="error">
                    <Delete
                      onClick={handleDeleteAttachment}
                      className='delete-icon'
                    />
                  </IconButton>
                </Box>
              </>
            ) : (
              <Typography variant="body1"></Typography>
            )}

          </Card>

          <Box className='buttonsrfp' display="flex" justifyContent="flex-end" flexDirection={{ xs: 'column', sm: 'row' }} mt={8}>
            <Button
              variant="outlined"
              onClick={() => navigate(-1)}
              className='border-primary text-primary text-capitalize rounded-3'
              sx={{ width: { xs: '100%', sm: 'auto' }, marginBottom: { xs: 2, sm: 0 }, mx: { sm: 2 } }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              className='bg-primary text-capitalize rounded-3'
              onClick={handleUpdate}
              sx={{ width: { xs: '100%', sm: 'auto' } }}
            >
              Update
            </Button>
          </Box>
        </Paper>
      </Box>

      <Modal open={isPdfOpen} onClose={togglePdfView}>
        <Box
          width="80%"
          height="80%"
          margin="auto"
          bgcolor="white"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          mt="5%"
          p={2}
        >
          <CloseIcon onClick={togglePdfView} style={{ alignSelf: 'flex-end', cursor: 'pointer' }} />
          {attachment && (
            <iframe
              src={attachment}
              width="100%"
              height="100%"
              title="PDF Viewer"
              style={{ border: 'none' }}
            />
          )}
        </Box>
      </Modal>

      {/* <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
          Section updated successfully!
        </Alert>
      </Snackbar> */}

      <Snackbar
        open={openDeleteSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity="warning" sx={{ width: '100%' }}>
          Attached File Deleted
        </Alert>
      </Snackbar>

      <EditFileUpload
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        setFileBase64={(newBase64) => {
          setFileBase64(newBase64);
          setAttachments(newBase64);
          if (attachment) {
            showSnackbar('Attachment updated successfully');
          } else {
            showSnackbar('New file attached successfully');
          }
          setOpenSnackbar(true);
        }}
        setFileName={setFileName}
      />

    </Container>
  );
};

export default EditSectionDetails;