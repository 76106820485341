import React from 'react';
import Dashboard from '../Dashboard/Dashboard';

const DataReports = ({ }) => {
  const hideFunctions = true;

  return (
    <div className='rfpDtailsContainer mt-2' maxWidth="100%">
      <div style={{ marginTop: 25 }}>
        <Dashboard hideFunctions={hideFunctions} />
      </div>
    </div>
  );
};

export default DataReports;