import { useState } from "react";
import { Routes, Route } from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme/Theme";
import { SnackbarProvider } from './pages/SnackbarContext';
import NotFound from "./pages/NotFound";
import NotFoundAuth from "./pages/NotFoundAuth";
import LoginPage from "./pages/Authentication/Login";
import Signup from "./pages/Authentication/Signup";
import ForgotPassword from "./pages/Authentication/ForgotPassword";
import NewPassword from "./pages/Authentication/NewPassword";
import OtpVerify from "./pages/Authentication/OTPverify";
import PDFView from "./pages/Dashboard/PDFView";
import Dashboard from "./pages/Dashboard";
import AddNewRpfPage from "./pages/Dashboard/AddNewRfp"
import MicrosoftRFPDetails from "./pages/Dashboard/MicroSoftRfpDetails";
import ExtrctedPdfDocument from "./pages/Dashboard/ExtractedPdfDocument"
import MicrosoftRFP from "./pages/Dashboard/ExtractRfpDocuments/MicrosoftRFP"
import VisualReports from "./pages/VisualReports";
import ExtractRfpDocuments from "./pages/Dashboard/ExtractRfpDocuments";
import Prompts from "./pages/Prompts";
import Reports from "./pages/Reports";
import AddNewSection from "./pages/Content_Library/AddNewSection";
import AlertsNotifications from "./pages/Alerts_Notifications";
import ContentLibrary from "./pages/Content_Library/Content_Library";
import DataReports from "./pages/DataReports/DataReports";
import SectionDetails from "./pages/Content_Library/SectionDetails";
import EditSectionDetails from "./pages/Content_Library/EditSectionDetails";
import MainAppLayout from "./routes/MainLayout";
import MicrosoftRFPDetailsEditPage from './pages/Dashboard/MicrosoftRfpDetailsEditPage'
import ProtectedRoute from '../src/services/protectedRoutes'
import PublicRoute from './services/publicRoutes'
import NotificationsPanel from "./pages/Dashboard/Notifications";

function App() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);

  return (
    <SnackbarProvider>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <div style={{ fontFamily: 'Poppins, sans-serif' }}>
            <Routes>
              {/* Public Routes */}
              <Route element={<PublicRoute />}>
                <Route path="/" element={<LoginPage />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="*" element={<NotFound />} />
                <Route path="/notfoundd" element={<NotFoundAuth />} />
                <Route path="/signup" element={<Signup />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/new-password/:userid" element={<NewPassword />} />
                <Route path="/otp-verify" element={<OtpVerify />} />
              </Route>

              {/* Private Routes */}
              <Route element={<ProtectedRoute />}>
                <Route element={<MainAppLayout isSidebar={isSidebar} setIsSidebar={setIsSidebar} />}>
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="/view-document/:docUrl" element={<PDFView />} />
                  <Route path='/AddNewRfp' element={<AddNewRpfPage />} />
                  <Route path="/rfp-preview/:id" element={<MicrosoftRFPDetails />} />
                  <Route path="/MicrosoftRFPDetailsEditPage/:id" element={<MicrosoftRFPDetailsEditPage />} />
                  <Route path="/notifications/:rfpid/:userid" element={<NotificationsPanel />} />
                  <Route path='/dashboard/addNewRfp/ExtrctedPdfDocument' element={<ExtrctedPdfDocument />} />
                  <Route path='/dashboard/addNewRfp/MicrosoftRFP' element={<MicrosoftRFP />} />
                  <Route path="/visual-reports" element={<VisualReports />} />
                  <Route path="/extract-rfp-documents" element={<ExtractRfpDocuments />} />
                  <Route path="/prompts" element={<Prompts />} />
                  <Route path="/add-new-section" element={<AddNewSection />} />
                  <Route path="/reports" element={<Reports />} />
                  <Route path="/alerts-notifications" element={<AlertsNotifications />} />
                  <Route path="/content-library" element={<ContentLibrary />} />
                  <Route path="/data-reports" element={<DataReports />} />
                  <Route path="/section-details/:id" element={<SectionDetails />} />
                  <Route path="/edit-section-details/:id" element={<EditSectionDetails />} />
                </Route>
              </Route>
            </Routes>
          </div>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </SnackbarProvider>
  );
}

export default App;