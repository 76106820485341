import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { Card, TextField, Button, Select, MenuItem, Typography, Box, FormControl, Divider, Dialog, DialogTitle, DialogContent } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { Row, Col, Container } from 'react-bootstrap';
import axios from 'axios';
import dayjs from 'dayjs';
import Modal from '@mui/material/Modal';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { StaticDateTimePicker } from '@mui/x-date-pickers/StaticDateTimePicker';
import { IconButton } from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useSnackbar } from '../SnackbarContext';
import { ThemeProvider, createTheme } from "@mui/material/styles";
import GlobalVariable from "../../services/Global";

const baseApiUrl = GlobalVariable.BASE_API_URL;

const customTheme = createTheme({
    palette: {
        primary: {
            main: "#1976d2",
        },
    },
});

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 650,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 3,
    borderRadius: 4,

};

const EditRFP = () => {
    const { id } = useParams();
    const { showSnackbar } = useSnackbar();
    const [open, setOpen] = useState(false);
    const [formData, setFormData] = useState({
        id: '',
        first_review: '',
        rfp_issuer: '',
        rfp_type: '',
        location: '',
        budget_range: '',
        expected_timeline: '',
        website: '',
        contact_name: '',
        contact_email: '',
        conference_date: '',
        conference_information: '',
        questionnaire_date: '',
        status: '',
        submission_mode: '',
        submission_date: '',
        remarks: '',
        comments: ''
    });
    const [errors, setErrors] = useState({});
    const [isFormValid, setIsFormValid] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isEditable, setIsEditable] = useState(true);
    const [firstReviewDialogOpen, setfirstReviewDialogOpen] = useState(false);
    const [comment, setComment] = useState()
    const [isDropdownDisabled, setIsDropdownDisabled] = useState(false);
    const [conferenceDialogOpen, setConferenceDialogOpen] = useState(false);
    const [QuestionnaireDialogOpen, setQuestionnaireDialogOpen] = useState(false);
    const [submissionDialogOpen, setSubmissionDialogOpen] = useState(false);
    const [selectedConferenceDate, setSelectedConferenceDate] = useState(formData.conference_date ? dayjs(formData.conference_date) : null);
    const [selectedQuestionnaireDate, setSelectedQuestionnaireDate] = useState(formData.questionnaire_date ? dayjs(formData.questionnaire_date) : null);
    const [selectedSubmissionDate, setSelectedSubmissionDate] = useState(formData.submission_date ? dayjs(formData.submission_date) : null);
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(false);


    const formatDateTime = (date) => {
        return dayjs(date).format('YYYY-MM-DD HH:mm');
    };

    useEffect(() => {
        if (location.state && location.state.formData) {
            const updatedFormData = {
                ...location.state.formData,
                first_review: location.state.formData.first_review ? formatDateTime(location.state.formData.first_review) : '',
                conference_date: location.state.formData.conference_date ? formatDateTime(location.state.formData.conference_date) : '',
                questionnaire_date: location.state.formData.questionnaire_date ? formatDateTime(location.state.formData.questionnaire_date) : '',
                submission_date: location.state.formData.submission_date ? formatDateTime(location.state.formData.submission_date) : '',
            };
            setFormData(updatedFormData);
        }
    }, [location.state]);

    useEffect(() => {
        if (isSubmitted) {
            setIsFormValid(validateForm());
        }
    }, [formData, isSubmitted]);

    const formatToISO = (dateString) => {
        const date = new Date(dateString);
        return date.toISOString().split('T')[0];
    };

    const selectStyles = {
        '& .MuiOutlinedInput-root': { borderRadius: '10px' },
        '& .MuiOutlinedInput-notchedOutline': { borderRadius: '10px', height: '45px' },
        '& .MuiSelect-icon': { top: '8px', color: 'blue' },
        '& .MuiOutlinedInput-input': { paddingTop: '9px' }
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        if (name === 'first_review' || name === 'expectedTimeline' || name === 'conference_date' || name === 'questionnaire_date' || name === 'submission_date') {
        }
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleDateReviewAccept = (newValue) => {
        if (newValue) {
            handleInputChange({
                target: {
                    name: 'first_review',
                    value: newValue.toISOString(),
                },
            });
        }
        setfirstReviewDialogOpen(false);
    };

    const validateForm = () => {
        const newErrors = {};

        if (!formData.first_review) newErrors.first_review = 'First review date is required';
        if (!formData.rfp_issuer) newErrors.rfp_issuer = 'RFP issuer is required';
        if (!formData.rfp_type) newErrors.rfp_type = 'RFP type is required';
        if (!formData.location) newErrors.location = 'Location is required';
        if (!formData.budget_range) newErrors.budget_range = 'Budget range is required';
        if (!formData.expected_timeline) newErrors.expected_timeline = 'Expected timeline is required';
        if (!formData.website) newErrors.website = 'Website is required';
        if (!formData.contact_name) newErrors.contact_name = 'Contact name is required';
        if (!formData.contact_email) newErrors.contact_email = 'Contact email is required';
        if (!formData.conference_date) newErrors.conference_date = 'Conference date is required';
        if (!formData.questionnaire_date) newErrors.questionnaire_date = 'Questionnaire date is required';
        if (!formData.submission_date) newErrors.submission_date = 'Submission date is required';
        if (!formData.status) newErrors.status = 'Status is required';
        if (!formData.submission_mode) newErrors.submission_mode = 'Submission mode is required';
        if (!formData.remarks) newErrors.remarks = 'Remarks are required';
        if (!formData.comments) newErrors.comments = 'Comments are required';

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);

    };

    const handleSave = async () => {
        setLoading(true);
        setIsSubmitted(true);
        if (!validateForm()) return;
        try {
            const requestData = {
                rfpid: id,
                first_review: formatDateTime(formData.first_review),
                rfp_issuer: formData.rfp_issuer,
                rfp_type: formData.rfp_type,
                location: formData.location,
                budget_range: formData.budget_range,
                expected_timeline: formData.expected_timeline,
                website: formData.website,
                contact_name: formData.contact_name,
                contact_email: formData.contact_email,
                conference_date: formatDateTime(formData.conference_date),
                conference_information: formData.conference_information,
                questionnaire_date: formatDateTime(formData.questionnaire_date),
                submission_mode: formData.submission_mode,
                submission_date: formatDateTime(formData.submission_date),
                status: formData.status,
                conference_reason: "",
                questionnaire_reason: "",
            };

            const response = await axios.put(`${baseApiUrl}/dashboard/editrfp`, requestData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (response.data.status_code === 200) {
                setIsEditable(false);
                navigate('/dashboard');
                showSnackbar("RFP updated successfully", "success");

            }

        } catch (error) {
            console.error('Error updating RFP:', error);
        }
        finally {
            setLoading(false);
        }
    };



    const handleSaveComment = () => {
        const storedData = JSON.parse(localStorage.getItem('userDetails'));
        const userId = storedData.userDetails.id;

        const commentData = {
            userid: userId,
            comments: comment,
            rfpid: id,
            status: ''
        }
        axios.post(`${baseApiUrl}/dashboard/addrfpcomment`, commentData)
            .then(response => {
                setIsDropdownDisabled(true);
                showSnackbar("Comment updated successfully", "success");
                handleClose();

            })
            .catch(error => {
                console.error('Error saving comment:', error.response.data);
            });
    }

    const handleConferenceDateAccept = (newValue) => {
        if (newValue) {
            handleInputChange({
                target: {
                    name: 'conference_date',
                    value: newValue.toISOString(),
                },
            });
        }
        setConferenceDialogOpen(false);
    };

    const handleQuestionnaireDateAccept = (newValue) => {
        if (newValue) {
            handleInputChange({
                target: {
                    name: 'questionnaire_date',
                    value: newValue.toISOString(),
                },
            });
        }
        setQuestionnaireDialogOpen(false);
    };

    const handleSubmissionDateAccept = (newValue) => {
        if (newValue) {
            handleInputChange({
                target: {
                    name: 'submission_date',
                    value: newValue.toISOString(),
                },
            });
        }
        setSubmissionDialogOpen(false);
    };

    return (
        <Container fluid className='ps-3 pt-2 rfpDtailsContainer'>
            <div>
                <Card className='rounded-3'>
                    <Box style={{ paddingBottom: '16px' }}>
                        <div style={{ padding: '16px 0' }}>
                            <Row className="mb-2 align-items-center mx-1">
                                <Col xs={12} sm={6} md={6}>
                                    <Typography className='fontstylee MicroSoftRFPDetails_heading' sx={{ width: 'auto', whiteSpace: 'nowrap' }}>
                                        Edit RFP Details
                                    </Typography>
                                </Col>
                                <Col xs={12} sm={6} md={6} className=" d-flex justify-content-end">
                                    <Button className='border-primary text-primary text-capitalize rounded-2 '
                                        variant="outlined"
                                        color="primary"
                                        onClick={() => navigate(-1)}
                                    >
                                        Back
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                        <form style={{ marginTop: '6px' }}>
                            <Container fluid className=''>
                                <Backdrop
                                    sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
                                    open={loading}
                                >
                                    <CircularProgress className=' text-primary' />
                                </Backdrop>

                                <Box
                                    sx={{
                                        border: '1px solid #ccc',
                                        borderRadius: '10px',
                                        padding: '16px',
                                        position: 'relative'
                                    }}
                                    className='mb-5'
                                >
                                    <Typography
                                        sx={{
                                            position: 'absolute',
                                            top: '-10px',
                                            left: '20px',
                                            background: '#fff',
                                            padding: '0 8px',
                                            fontSize: '14px',
                                            fontWeight: 'bold'
                                        }}
                                    >
                                        RFP Details
                                    </Typography>
                                    <Row className='pb-2'>



                                        <Col lg={3} md={6}>
                                            <FormControl fullWidth margin="normal" className="mt-2 inputfields">
                                                <Typography className='fontstyleet' variant="h6" gutterBottom>
                                                    First Review<span style={{ color: "#ff0000" }}>*</span>
                                                </Typography>
                                                <TextField
                                                    name="first_review"
                                                    value={
                                                        formData.first_review ? dayjs(formData.first_review).format('YYYY-MM-DD HH:mm') : ''}
                                                    onChange={(e) => handleInputChange(e)}
                                                    placeholder="Select date"
                                                    InputProps={{
                                                        readOnly: true,
                                                        endAdornment: (
                                                            <IconButton
                                                                onClick={() => setfirstReviewDialogOpen(true)}
                                                                color="primary"
                                                                size="large"
                                                            >
                                                                <CalendarMonthIcon sx={{ color: '#0175FF' }} />
                                                            </IconButton>
                                                        ),
                                                    }}
                                                    sx={{
                                                        '& .MuiOutlinedInput-root': {
                                                            borderRadius: '10px',
                                                        },
                                                        '& .MuiInputBase-root': {
                                                            height: '40px',
                                                        },
                                                    }}
                                                />
                                                {errors.first_review && (
                                                    <Typography color="error">{errors.first_review}</Typography>
                                                )}

                                                <Dialog
                                                    open={firstReviewDialogOpen}
                                                    onClose={() => setfirstReviewDialogOpen(false)}
                                                    maxWidth="xs"
                                                    fullWidth
                                                    PaperProps={{
                                                        style: {
                                                            height: '85%',
                                                            width: '100%',
                                                            borderRadius: '12px',
                                                            position: 'fixed',
                                                            top: '50%',
                                                            left: '50%',
                                                            transform: 'translate(-50%, -50%)',
                                                            overflow: 'auto',
                                                        },
                                                    }}
                                                    className='dialogpaperstyles'
                                                >
                                                    <DialogTitle>
                                                        Select First Review and Time
                                                        <IconButton
                                                            edge="end"
                                                            color="inherit"
                                                            onClick={() => setfirstReviewDialogOpen(false)}
                                                            aria-label="close"
                                                            sx={{
                                                                position: 'absolute',
                                                                right: 9,
                                                                top: 8,
                                                                color: (theme) => theme.palette.grey[500],
                                                            }}
                                                        >
                                                            <CloseIcon />
                                                        </IconButton>
                                                    </DialogTitle>
                                                    <DialogContent>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <ThemeProvider theme={customTheme}>
                                                                <StaticDateTimePicker
                                                                    value={selectedConferenceDate}
                                                                    minDate={dayjs("2024-04-01 12:00")}
                                                                    onAccept={handleDateReviewAccept}
                                                                    onClose={() => setfirstReviewDialogOpen(false)}
                                                                    ampm={false}
                                                                />
                                                            </ThemeProvider>
                                                        </LocalizationProvider>
                                                    </DialogContent>
                                                </Dialog>
                                            </FormControl>
                                        </Col>

                                        <Col lg={6} md={6}>
                                            <FormControl fullWidth margin="normal" className="inputfields">
                                                <Typography>RFP ISSUER<span style={{ color: "#ff0000" }}>*</span></Typography>
                                                <TextField
                                                    placeholder="Issuer Name"
                                                    name="rfp_issuer"
                                                    onChange={handleInputChange}
                                                    autoComplete='off'
                                                    value={formData.rfp_issuer}
                                                    sx={{
                                                        '& .MuiOutlinedInput-root': {
                                                            borderRadius: '10px',
                                                        },
                                                        '& .MuiInputBase-root': {
                                                            height: '40px',
                                                        },
                                                    }}
                                                    error={isSubmitted && !!errors.rfp_issuer}
                                                    helperText={isSubmitted && errors.rfp_issuer}
                                                    disabled={!isEditable}

                                                />
                                            </FormControl>
                                        </Col>
                                        <Col lg={3}>
                                            <FormControl fullWidth margin="normal" className="inputfields">
                                                <Typography>Rfp Type<span style={{ color: "#ff0000" }}>*</span></Typography>
                                                <Select
                                                    value={formData.rfp_type}
                                                    onChange={handleInputChange}
                                                    name="rfp_type"
                                                    sx={{
                                                        '& .MuiOutlinedInput-root': {
                                                            borderRadius: '10px',
                                                        },
                                                        '& .MuiOutlinedInput-notchedOutline': {
                                                            borderRadius: '10px',
                                                            height: '45px',
                                                        },
                                                        '& .MuiSelect-icon': {
                                                            top: '8px',
                                                            color: 'blue',
                                                        },
                                                        '& .MuiOutlinedInput-input': {
                                                            paddingTop: '9px',
                                                        },
                                                    }}
                                                    error={isSubmitted && !!errors.rfp_type}
                                                    disabled={!isEditable}
                                                >
                                                    <MenuItem className='subheader' value="Solutioning">Solutioning</MenuItem>
                                                    <MenuItem className='subheader' value="Staffing">Staffing</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={3} md={6}>
                                            <FormControl fullWidth margin="normal" className="inputfields">
                                                <Typography>Location<span style={{ color: "#ff0000" }}>*</span></Typography>
                                                <TextField
                                                    placeholder="Location"
                                                    name="location"
                                                    value={formData.location}
                                                    autoComplete='off'
                                                    sx={{
                                                        '& .MuiOutlinedInput-root': {
                                                            borderRadius: '10px',
                                                        },
                                                        '& .MuiInputBase-root': {
                                                            height: '40px',
                                                        },
                                                    }}

                                                    onChange={handleInputChange}
                                                    error={isSubmitted && !!errors.location}
                                                    helperText={isSubmitted && errors.location}
                                                    disabled={!isEditable}
                                                />
                                            </FormControl>
                                        </Col>
                                        <Col lg={3} md={6}>
                                            <FormControl fullWidth margin="normal" className="inputfields">
                                                <Typography>Budget Range<span style={{ color: "#ff0000" }}>*</span></Typography>
                                                <TextField
                                                    placeholder="Budget Range"
                                                    name="budget_range"
                                                    value={formData.budget_range}
                                                    sx={{
                                                        '& .MuiOutlinedInput-root': {
                                                            borderRadius: '10px',
                                                        },
                                                        '& .MuiInputBase-root': {
                                                            height: '40px',
                                                        },
                                                    }}
                                                    onChange={handleInputChange}
                                                    error={isSubmitted && !!errors.budget_range}
                                                    helperText={isSubmitted && errors.budget_range}
                                                    disabled={!isEditable}
                                                    autoComplete='off'

                                                />
                                            </FormControl>
                                        </Col>
                                        <Col lg={3}>
                                            <FormControl fullWidth margin="normal" className="inputfields">
                                                <Typography>Expected Timeline<span style={{ color: "#ff0000" }}>*</span></Typography>
                                                <TextField
                                                    placeholder="Expected Timeline"
                                                    name="expected_timeline"
                                                    value={formData.expected_timeline}
                                                    sx={{
                                                        '& .MuiOutlinedInput-root': {
                                                            borderRadius: '10px',
                                                        },
                                                        '& .MuiInputBase-root': {
                                                            height: '40px',
                                                        },
                                                    }}
                                                    onChange={handleInputChange}
                                                    error={isSubmitted && !!errors.expected_timeline}
                                                    helperText={isSubmitted && errors.expected_timeline}
                                                    disabled={!isEditable}
                                                    autoComplete='off'

                                                />
                                            </FormControl>
                                        </Col>
                                        <Col lg={3}>
                                            <FormControl fullWidth margin="normal" className="inputfields">
                                                <Typography>Website<span style={{ color: "#ff0000" }}>*</span></Typography>
                                                <TextField
                                                    placeholder="Website"
                                                    name="website"
                                                    autoComplete='off'
                                                    value={formData.website}
                                                    sx={{
                                                        '& .MuiOutlinedInput-root': {
                                                            borderRadius: '10px',
                                                        },
                                                        '& .MuiInputBase-root': {
                                                            height: '40px',
                                                        },
                                                    }}
                                                    onChange={handleInputChange}
                                                    error={isSubmitted && !!errors.website}
                                                    helperText={isSubmitted && errors.website}
                                                    disabled={!isEditable}

                                                />
                                            </FormControl>
                                        </Col>
                                    </Row>
                                </Box>
                                <Box
                                    sx={{
                                        border: '1px solid #ccc',
                                        borderRadius: '10px',
                                        padding: '16px',

                                        position: 'relative'
                                    }}
                                    className='mb-5'

                                >
                                    <Typography
                                        sx={{
                                            position: 'absolute',
                                            top: '-10px',
                                            left: '20px',
                                            background: '#fff',
                                            padding: '0 8px',
                                            fontSize: '14px',
                                            fontWeight: 'bold'
                                        }}
                                        className='fontstylee'
                                    >
                                        Point of Contact Details
                                    </Typography>
                                    <Row>
                                        <Col lg={3}>
                                            <FormControl fullWidth margin="normal" className="inputfields">
                                                <Typography>Name<span style={{ color: "#ff0000" }}>*</span></Typography>
                                                <TextField
                                                    placeholder="contact_name"
                                                    name="contact_name"
                                                    value={formData.contact_name}
                                                    autoComplete='off'
                                                    sx={{
                                                        '& .MuiOutlinedInput-root': {
                                                            borderRadius: '10px',
                                                        },
                                                        '& .MuiInputBase-root': {
                                                            height: '40px',
                                                        },
                                                    }}
                                                    onChange={handleInputChange}
                                                    error={isSubmitted && !!errors.contact_name}
                                                    helperText={isSubmitted && errors.contact_name}
                                                    disabled={!isEditable}

                                                />
                                            </FormControl>
                                        </Col>
                                        <Col lg={3}>
                                            <FormControl fullWidth margin="normal" className="inputfields">
                                                <Typography>Email<span style={{ color: "#ff0000" }}>*</span></Typography>
                                                <TextField
                                                    placeholder="contact_email"
                                                    name="contact_email"
                                                    value={formData.contact_email}
                                                    autoComplete='off'
                                                    sx={{
                                                        '& .MuiOutlinedInput-root': {
                                                            borderRadius: '10px',
                                                        },
                                                        '& .MuiInputBase-root': {
                                                            height: '40px',
                                                        },
                                                    }}
                                                    onChange={handleInputChange}
                                                    error={isSubmitted && !!errors.contact_email}
                                                    helperText={isSubmitted && errors.contact_email}
                                                    disabled={!isEditable}

                                                />
                                            </FormControl>
                                        </Col>
                                    </Row>
                                </Box>
                                <Box
                                    sx={{
                                        border: '1px solid #ccc',
                                        borderRadius: '10px',
                                        padding: '16px',

                                        position: 'relative'
                                    }}
                                    className='mb-5'
                                >
                                    <Typography
                                        sx={{
                                            position: 'absolute',
                                            top: '-10px',
                                            left: '20px',
                                            background: '#fff',
                                            padding: '0 8px',
                                            fontSize: '14px',
                                            fontWeight: 'bold'
                                        }}
                                    >
                                        Submission Details
                                    </Typography>
                                    <Row className='pb-2'>


                                        <Col lg={3} md={6}>
                                            <FormControl fullWidth margin="normal" className="mt-2 inputfields" >
                                                <Typography variant="h6" className='fontstyleet' gutterBottom>
                                                    Conference Date<span style={{ color: "#ff0000" }}>*</span>
                                                </Typography>
                                                <TextField
                                                    name="conference_date"
                                                    value={
                                                        formData.conference_date ? dayjs(formData.conference_date).format('YYYY-MM-DD HH:mm') : ''}
                                                    placeholder="Select date"
                                                    InputProps={{
                                                        readOnly: true,
                                                        endAdornment: (
                                                            <IconButton
                                                                onClick={() => setConferenceDialogOpen(true)}
                                                                color="primary"
                                                                size="large"
                                                            >
                                                                <CalendarMonthIcon sx={{ color: '#0175FF' }} />
                                                            </IconButton>
                                                        ),
                                                    }}
                                                    sx={{
                                                        '& .MuiOutlinedInput-root': {
                                                            borderRadius: '10px',
                                                        },
                                                        '& .MuiInputBase-root': {
                                                            height: '40px',
                                                        },
                                                    }}
                                                />
                                                {errors.conference_date && (
                                                    <Typography color="error">{errors.conference_date}</Typography>
                                                )}

                                                <Dialog
                                                    open={conferenceDialogOpen}
                                                    onClose={() => setConferenceDialogOpen(false)}
                                                    maxWidth="xs"
                                                    fullWidth
                                                    PaperProps={{
                                                        style: {
                                                            height: '85%',
                                                            width: '100%',
                                                            borderRadius: '12px',
                                                            position: 'fixed',
                                                            top: '50%',
                                                            left: '50%',
                                                            transform: 'translate(-50%, -50%)',
                                                            overflow: 'auto',
                                                        },
                                                    }}
                                                >
                                                    <DialogTitle>
                                                        Select Conference Date and Time
                                                        <IconButton
                                                            edge="end"
                                                            color="inherit"
                                                            onClick={() => setConferenceDialogOpen(false)}
                                                            aria-label="close"
                                                            sx={{
                                                                position: 'absolute',
                                                                right: 9,
                                                                top: 8,
                                                                color: (theme) => theme.palette.grey[500],
                                                            }}
                                                        >
                                                            <CloseIcon />
                                                        </IconButton>
                                                    </DialogTitle>
                                                    <DialogContent>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <ThemeProvider theme={customTheme}>
                                                                <StaticDateTimePicker
                                                                    value={selectedConferenceDate}
                                                                    minDate={dayjs("2024-10-01")}
                                                                    onAccept={handleConferenceDateAccept}

                                                                    onClose={() => setConferenceDialogOpen(false)}
                                                                    ampm={false}
                                                                />
                                                            </ThemeProvider>
                                                        </LocalizationProvider>
                                                    </DialogContent>
                                                </Dialog>
                                            </FormControl>
                                        </Col>

                                        <Col lg={3} md={6}>
                                            <FormControl fullWidth margin="normal" className="inputfields">
                                                <Typography>Conference Information<span style={{ color: "#ff0000" }}>*</span></Typography>
                                                <TextField
                                                    placeholder="Conference Information"
                                                    name="conference_information"
                                                    value={formData.conference_information}
                                                    autoComplete='off'
                                                    sx={{
                                                        '& .MuiOutlinedInput-root': {
                                                            borderRadius: '10px',
                                                        },
                                                        '& .MuiInputBase-root': {
                                                            height: '40px',
                                                        },
                                                    }}
                                                    onChange={handleInputChange}
                                                    error={isSubmitted && !!errors.conference_information}
                                                    helperText={isSubmitted && errors.conference_information}
                                                    disabled={!isEditable}


                                                />
                                            </FormControl>
                                        </Col>



                                        <Col lg={3} md={6}>
                                            <FormControl fullWidth margin="normal" className='mt-2 inputfields'>
                                                <Typography variant="h6" className='fontstyleet' gutterBottom>
                                                    Questionnaire Date<span style={{ color: "#ff0000" }}>*</span>
                                                </Typography>
                                                <TextField
                                                    name="questionnaire_date"
                                                    value={formData.questionnaire_date ? dayjs(formData.questionnaire_date).format('YYYY-MM-DD HH:mm') : ''}
                                                    onChange={(e) => handleInputChange(e)}
                                                    placeholder="Select date"
                                                    InputProps={{
                                                        readOnly: true,
                                                        endAdornment: (
                                                            <IconButton
                                                                onClick={() => setQuestionnaireDialogOpen(true)}
                                                                color="primary"
                                                                size="large"
                                                            >
                                                                <CalendarMonthIcon sx={{ color: '#0175FF' }} />
                                                            </IconButton>
                                                        ),
                                                    }}
                                                    sx={{
                                                        '& .MuiOutlinedInput-root': {
                                                            borderRadius: '10px'
                                                        },
                                                        '& .MuiInputBase-root': {
                                                            height: '40px'
                                                        }
                                                    }}
                                                />
                                                {errors.questionnaire_date && <Typography color="error">{errors.questionnaire_date}</Typography>}

                                                <Dialog
                                                    open={QuestionnaireDialogOpen}
                                                    onClose={() => setQuestionnaireDialogOpen(false)}
                                                    maxWidth="xs"
                                                    fullWidth
                                                    PaperProps={{
                                                        style: {
                                                            height: '85%',
                                                            width: '100%',
                                                            borderRadius: '12px',
                                                            position: 'fixed',
                                                            top: '50%',
                                                            left: '50%',
                                                            transform: 'translate(-50%, -50%)',
                                                            overflow: 'auto',
                                                        },
                                                    }}
                                                >
                                                    <DialogTitle>
                                                        Select Questionnaire Date and Time
                                                        <IconButton
                                                            edge="end"
                                                            color="inherit"
                                                            onClick={() => setQuestionnaireDialogOpen(false)}
                                                            aria-label="close"
                                                            sx={{
                                                                position: 'absolute',
                                                                right: 9,
                                                                top: 8,
                                                                color: (theme) => theme.palette.grey[500]
                                                            }}
                                                        >
                                                            <CloseIcon />
                                                        </IconButton>
                                                    </DialogTitle>
                                                    <DialogContent>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <ThemeProvider theme={customTheme}>
                                                                <StaticDateTimePicker
                                                                    value={selectedQuestionnaireDate}
                                                                    minDate={dayjs("2024-10-01")}
                                                                    onAccept={handleQuestionnaireDateAccept}
                                                                    onClose={() => setQuestionnaireDialogOpen(false)}
                                                                    ampm={false}
                                                                />
                                                            </ThemeProvider>
                                                        </LocalizationProvider>
                                                    </DialogContent>

                                                </Dialog>
                                            </FormControl>
                                        </Col>

                                        <Col lg={3} md={6}>
                                            <FormControl fullWidth margin="normal" className="inputfields">
                                                <Typography>Status<span style={{ color: "#ff0000" }}>*</span></Typography>
                                                <Select
                                                    value={formData.status}
                                                    onChange={handleInputChange}
                                                    name="status"
                                                    sx={{
                                                        '& .MuiOutlinedInput-root': {
                                                            borderRadius: '10px',
                                                            top: '2px'
                                                        },
                                                        '& .MuiOutlinedInput-notchedOutline': {
                                                            borderRadius: '10px',
                                                            height: '45px',
                                                        },
                                                        '& .MuiSelect-icon': {
                                                            top: '8px',
                                                            color: 'blue',
                                                        },
                                                        '& .MuiOutlinedInput-input': {
                                                            paddingTop: '9px',
                                                        },
                                                    }}
                                                    error={isSubmitted && !!errors.status}
                                                    helperText={isSubmitted && errors.status}
                                                    disabled={isDropdownDisabled || !isEditable}

                                                >
                                                    <MenuItem value="1">New</MenuItem>
                                                    <MenuItem value="2">In Progress</MenuItem>
                                                    <MenuItem value="3" onClick={handleOpen}>On Hold</MenuItem>
                                                    <MenuItem value="4">Submitted</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={3} md={6}>
                                            <FormControl fullWidth margin="normal" className="inputfields">
                                                <Typography>Submission Mode<span style={{ color: "#ff0000" }}>*</span></Typography>
                                                <Select
                                                    value={formData.submission_mode}
                                                    onChange={handleInputChange}
                                                    name="submission_mode"
                                                    sx={{
                                                        '& .MuiOutlinedInput-root': {
                                                            borderRadius: '10px',
                                                        },
                                                        '& .MuiOutlinedInput-notchedOutline': {
                                                            borderRadius: '10px',
                                                            height: '45px',
                                                        },
                                                        '& .MuiSelect-icon': {
                                                            top: '8px',
                                                            color: 'blue',
                                                        },
                                                        '& .MuiOutlinedInput-input': {
                                                            paddingTop: '9px',
                                                        },
                                                    }}
                                                    error={isSubmitted && !!errors.submission_mode}
                                                    helperText={isSubmitted && errors.submission_mode}
                                                    disabled={!isEditable}

                                                >
                                                    <MenuItem value="Portal">Portal</MenuItem>
                                                    <MenuItem value="Email">Email</MenuItem>
                                                    <MenuItem value="Physical">Physical</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Col>

                                        <Col lg={3} md={6}>
                                            <FormControl fullWidth margin="normal" className='mt-2 inputfields'>
                                                <Typography variant="h6" className='fontstyleet' gutterBottom>
                                                    Submission Date<span style={{ color: "#ff0000" }}>*</span>
                                                </Typography>
                                                <TextField
                                                    name="submission_date"
                                                    value={formData.submission_date ? dayjs(formData.submission_date).format('YYYY-MM-DD HH:mm') : ''}
                                                    onChange={(e) => handleInputChange(e)}
                                                    placeholder="Select date"
                                                    InputProps={{
                                                        readOnly: true,
                                                        endAdornment: (
                                                            <IconButton
                                                                onClick={() => setSubmissionDialogOpen(true)}
                                                                color="primary"
                                                                size="large"
                                                            >
                                                                <CalendarMonthIcon sx={{ color: '#0175FF' }} />
                                                            </IconButton>
                                                        ),
                                                    }}
                                                    sx={{
                                                        '& .MuiOutlinedInput-root': {
                                                            borderRadius: '10px'
                                                        },
                                                        '& .MuiInputBase-root': {
                                                            height: '40px'
                                                        }
                                                    }}
                                                />
                                                {errors.submission_date && <Typography color="error">{errors.submission_date}</Typography>}

                                                <Dialog
                                                    open={submissionDialogOpen}
                                                    onClose={() => setSubmissionDialogOpen(false)}
                                                    maxWidth="xs"
                                                    fullWidth
                                                    PaperProps={{
                                                        style: {
                                                            height: '85%',
                                                            width: '100%',
                                                            borderRadius: '12px',
                                                            position: 'fixed',
                                                            top: '50%',
                                                            left: '50%',
                                                            transform: 'translate(-50%, -50%)',
                                                            overflow: 'auto',
                                                        },
                                                    }}
                                                >
                                                    <DialogTitle>
                                                        Select Submission Date and Time
                                                        <IconButton
                                                            edge="end"
                                                            color="inherit"
                                                            onClick={() => setSubmissionDialogOpen(false)}
                                                            aria-label="close"
                                                            sx={{
                                                                position: 'absolute',
                                                                right: 9,
                                                                top: 8,
                                                                color: (theme) => theme.palette.grey[500]
                                                            }}
                                                        >
                                                            <CloseIcon />
                                                        </IconButton>
                                                    </DialogTitle>
                                                    <DialogContent>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <ThemeProvider theme={customTheme}>
                                                                <StaticDateTimePicker
                                                                    value={selectedSubmissionDate}
                                                                    minDate={dayjs()}
                                                                    onAccept={handleSubmissionDateAccept}
                                                                    onClose={() => setSubmissionDialogOpen(false)}
                                                                    ampm={false}
                                                                />
                                                            </ThemeProvider>
                                                        </LocalizationProvider>
                                                    </DialogContent>

                                                </Dialog>
                                            </FormControl>
                                        </Col>
                                    </Row>
                                </Box>

                                <Box sx={{ display: "flex", justifyContent: 'end', marginTop: '10px' }}>
                                    <Button
                                        variant="contained"
                                        className={`bg-primary text-capitalize rounded-3 `}
                                        onClick={handleSave}
                                    >
                                        Save
                                    </Button>
                                </Box>
                            </Container>
                        </form>
                    </Box>
                </Card>
            </div>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className='commentsModal'>
                    <Box display="flex" justifyContent="space-between" alignItems="baseline" >
                        <Typography gutterBottom className='commentsText'>
                            Comments
                        </Typography>
                        <CloseIcon onClick={handleClose} style={{ cursor: 'pointer' }} />
                    </Box>
                    <Divider style={{ borderColor: '#adadad' }} />
                    <Typography id="modal-modal-description" sx={{ mt: 2 }} className='addCommentText'>
                        Add Comments
                    </Typography>
                    <TextField
                        fullWidth
                        label="Comment"
                        variant="outlined"
                        className=' mb-2 rounded-5 mt-3'
                        multiline
                        rows={2}
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                borderRadius: '16px',
                            },
                        }}
                    />
                    <Button variant="contained" className="mb-2 mt-1 float-end bg-primary rounded-5 " onClick={handleSaveComment}>
                        Save
                    </Button>

                </Box>
            </Modal>
        </Container>
    );
};

export default EditRFP;