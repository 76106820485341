import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Paper,
  Button,
  Box,
  TextField,
  IconButton,
  Card,
  Modal,
  CircularProgress,
  FormControl
} from '@mui/material';
import { Close as CloseIcon, Delete, Edit } from '@mui/icons-material';
import { AttachFile } from '@mui/icons-material';
import { useNavigate, useLocation } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import axios from 'axios';
import { useSnackbar } from '../SnackbarContext';
import FileUpload from './FileUpload';
import PDF from '../../assets/Images/PDF.svg';
import GlobalVariable from "../../services/Global";

const baseApiUrl = GlobalVariable.BASE_API_URL;


const CustomToolbar = ({ handleAttachFileClick }) => (
  <div id="toolbar">
    <select className="ql-font">
      <option value="sans-serif" selected>
        Sans Serif
      </option>
      <option value="serif">Serif</option>
      <option value="monospace">Monospace</option>
    </select>
    <select className="ql-header">
      <option value="1">Heading 1</option>
      <option value="2">Heading 2</option>
      <option value="">Normal</option>
    </select>
    <button className="ql-bold"></button>
    <button className="ql-italic"></button>
    <button className="ql-underline"></button>
    <button className="ql-strike"></button>
    <button className="ql-list" value="ordered"></button>
    <button className="ql-list" value="bullet"></button>
    <select className="ql-align"></select>
    <button className="ql-link"></button>
    <button className="ql-image"></button>
    <button onClick={handleAttachFileClick}>
      <AttachFile style={{ fontSize: 18 }} />
    </button>
  </div>
);


const modules = {
  toolbar: {
    container: '#toolbar',
  },
};

const formats = [
  'font',
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'list',
  'bullet',
  'align',
  'link',
  'image',
];

const AddNewSection = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { showSnackbar } = useSnackbar();
  const [sectionName, setSectionName] = useState('');
  const [description, setDescription] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [fileBase64, setFileBase64] = useState('');
  const [fileName, setFileName] = useState('');
  const [isPdfOpen, setPdfOpen] = useState(false);
  const [errors, setErrors] = useState({ sectionName: '', description: '' });
  const [loading, setLoading] = useState(false);


  const validate = () => {
    let isValid = true;
    const newErrors = { sectionName: '', description: '' };

    if (!sectionName.trim()) {
      newErrors.sectionName = 'Section Name is required';
      isValid = false;
    } else if (sectionName.length < 3) {
      newErrors.sectionName = 'Section Name must be at least 3 characters long';
      isValid = false;
    }

    if (!description.trim()) {
      newErrors.description = 'Description is required';
      isValid = false;
    } else if (description.length < 10) {
      newErrors.description = 'Description must be at least 10 characters long';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSave = async () => {
    if (!validate()) return;
    console.log('Saving section...');
    setLoading(true);
    try {
      const requestData = {
        content_name: sectionName,
        content_description: description,
        content_file: fileBase64,
        content_filename: fileName,
      };

      const response = await axios.post( `${baseApiUrl}/contentlibrary/addclsection`,
        requestData,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 200) {
        showSnackbar('Section added successfully!');
        navigate('/content-library');
        setLoading(false);
      }
    } catch (error) {
      console.error('Error adding section:', error);
      showSnackbar('Error adding section. Please try again.', 'error');
      setLoading(false);
    }
  };

  const handleAttachFileClick = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleSectionNameChange = (e) => {
    setSectionName(e.target.value);
    if (errors.sectionName) {
      setErrors((prevErrors) => ({ ...prevErrors, sectionName: '' }));
    }
  };

  const handleDescriptionChange = (value) => {
    setDescription(value);
    if (errors.description) {
      setErrors((prevErrors) => ({ ...prevErrors, description: '' }));
    }
  };
  const handleDeleteAttachment = () => {
    setFileBase64('');
    setFileName('');
  };

  const togglePdfView = () => {
    setPdfOpen(!isPdfOpen);
  };


  return (
    <Container fluid className="rfpDtailsContainer" maxWidth="100%">
      <Box mt={2}>
        <Paper elevation={3} style={{ padding: 20, borderRadius: 15, width: '100%' }}>
          {loading && (
            <Box
              className="circular-progress"
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: 1000,
              }}
            >
              <CircularProgress className=" text-primary" />
            </Box>
          )}
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h4">
              <strong>Add New Section</strong>
            </Typography>
            <Button
              variant="outlined"
              className="border-primary text-primary text-capitalize rounded-3"
              onClick={() => navigate(-1)}
            >
              Back
            </Button>
          </Box>
          <Box mt={2}>
            <FormControl fullWidth margin="normal" className=" inputfields">
              <Typography variant="h6">
                <strong>Section Name</strong>
              </Typography>
              <TextField
                value={sectionName}
                placeholder="Section Name"
                onChange={handleSectionNameChange}
                variant="outlined"
                fullWidth
                sx={{ marginTop: 1, '& .MuiOutlinedInput-root': { borderRadius: '20px', width: { xs: '100%', sm: '75%', md: '50%' }, } }}
                error={!!errors.sectionName}
                helperText={errors.sectionName}
              />
            </FormControl>
          </Box>
          <Box mt={3}>
            <Typography variant="h6">
              <strong>Description</strong>
            </Typography>
          </Box>
          <Box>
            <CustomToolbar handleAttachFileClick={handleAttachFileClick} />
            <ReactQuill
              value={description}
              onChange={handleDescriptionChange}
              modules={modules}
              formats={formats}
              placeholder="Section Description"
              style={{ height: '150px', marginTop: '10px' }}
            />
            {errors.description && (
              <Typography color="error" variant="body2">
                {errors.description}
              </Typography>
            )}
          </Box>
          <Box mt={3}>
            {fileBase64 ? (
              <Typography variant="h6">
                <strong>Attachment</strong>
              </Typography>
            ) : null}
          </Box>
          <Card sx={{ width: '200px', justifyItems: 'center' }} >
            {fileBase64 ? (
              <>

                <img src={PDF} alt="PDF Icon"
                  onClick={togglePdfView}
                  style={{ cursor: 'pointer', color: '#0175FF', paddingTop: 5 }} />
                <Typography
                  variant="body1"
                  onClick={togglePdfView}
                  style={{ cursor: 'pointer', color: '#0175FF' }}
                >
                  {fileName}
                </Typography>
                <Box display={'flex'} mt={2}>
                  <IconButton color="error">
                    <Delete
                      onClick={handleDeleteAttachment}
                      className='delete-icon'
                    />
                  </IconButton>
                </Box>
              </>
            ) : (
              <Typography variant="body1"></Typography>
            )}

          </Card>
          <Box mt={3} display="flex" justifyContent="flex-end" marginTop={6}>
            <Button variant="contained" className="bg-primary text-capitalize rounded-3" onClick={handleSave}>
              Save
            </Button>
          </Box>
        </Paper>
      </Box>
      <FileUpload open={openDialog} onClose={handleDialogClose} setFileName={setFileName} setFileBase64={setFileBase64} />
      <Modal open={isPdfOpen} onClose={togglePdfView}>
        <Box
          width="80%"
          height="80%"
          margin="auto"
          bgcolor="white"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          mt="5%"
          p={2}
        >
          <CloseIcon onClick={togglePdfView} style={{ alignSelf: 'flex-end', cursor: 'pointer' }} />
          {fileBase64 && (
            <iframe
              src={fileBase64}
              width="100%"
              height="100%"
              title="PDF Viewer"
              style={{ border: 'none' }}
            />
          )}
        </Box>
      </Modal>
    </Container>
  );
};

export default AddNewSection;
