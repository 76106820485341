import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { TextField, Button, Typography, Box, Grid, InputLabel, Snackbar, Alert } from '@mui/material';
import { styled } from '@mui/system';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Arrow from '../../assets/Images/Arrow.svg'
import './Responsive.css'
import GlobalVariable from "../../services/Global";


const baseApiUrl = GlobalVariable.BASE_API_URL;

const Root = styled('div')(({ theme }) => ({
  height: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const FormContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(1),
  height: '100%',
  justifyContent: 'center',
}));

const Form = styled('form')(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(1),
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(3, 0, 2),
}));

const Media = styled('img')({
  height: '100%',
});



const OtpVerify = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [otp, setOtp] = useState(new Array(6).fill(''));
  const [error, setError] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [timer, setTimer] = useState(60);
  const [canResend, setCanResend] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (timer > 0) {
      const interval = setInterval(() => {
        setTimer(prevTimer => prevTimer - 1);
      }, 1000);
      return () => clearInterval(interval);
    } else {
      setCanResend(true);
    }
  }, [timer]);

  const handleChange = (element, index) => {
    const value = element.value.replace(/[^0-9]/g, '');

    if (value.length === 1) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (index < otp.length - 1) {
        document.getElementById(`otp-input-${index + 1}`).focus();
      }
    }
  };

  const handlePaste = (event) => {
    event.preventDefault();
    const pasteData = event.clipboardData.getData('Text').replace(/[^0-9]/g, '');

    if (pasteData.length > 0) {
      const newOtp = [...otp];
      for (let i = 0; i < pasteData.length && i < otp.length; i++) {
        newOtp[i] = pasteData[i];
      }
      setOtp(newOtp);

      const nextIndex = pasteData.length < otp.length ? pasteData.length : otp.length - 1;
      document.getElementById(`otp-input-${nextIndex}`).focus();
    }
  };

  const handleKeyDown = (event, index) => {
    if (event.key === 'Backspace') {
      if (otp[index] === '') {
        if (index > 0) {
          document.getElementById(`otp-input-${index - 1}`).focus();
        }
      } else {
        setOtp([...otp.map((d, idx) => (idx === index ? '' : d))]);
      }
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (otp.some((digit) => digit === '' || isNaN(digit))) {
      setError('Please enter a valid 6-digit OTP.');
      return;
    }

    setError('');
    setLoading(true);

    try {
      const response = await axios.post(`${baseApiUrl}/users/otpverify`, {
        otp: Number(otp.join('')),
        email: state?.email,
      });

      const { userid } = response.data;
      navigate(`/new-password/${userid}`, { state: { email: state?.email } });
    } catch (err) {
      console.error('Error verifying OTP:', err);

      if (err.response && err.response.status === 400) {
        setError('The OTP entered is incorrect. Please try again.');
      } else {
        setError('The OTP entered is incorrect. Please try again.');
      }
    } finally {
      setLoading(false);
    }
  };


  const handleResendOtp = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const response = await axios.post(`${baseApiUrl}/users/forgetpassword`, {
        email: state?.email,
      });

      setSnackbarMessage('OTP resent successfully!');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      setCanResend(false);
      setTimer(60);
    } catch (err) {
      console.error('Error resending OTP:', err);
      if (err.response && err.response.status === 404) {
        navigate('/notfoundd');
      } else {
        setSnackbarMessage('Failed to resend OTP. Please try again.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    } finally {
      setLoading(false);
    }
  };


  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Root>
      <Grid container component="main" display='flex' sx={{ height: '100vh', width: '100%' }}>
        <Grid item xs={false} sm={7} md={7} lg={8} className="hide-on-mobile">
          <Media
            src="Loginimg.jpg"
            alt="Welcome"
            sx={{ width: '100%', height: '100%', objectFit: 'cover' }}

          />
        </Grid>
        <Grid item xs={12} sm={5} md={5} lg={4} className='position-relative' >
          <FormContainer sx={{ height: '100%' }}>
            <Box className='position-absolute top-0 start-0 d-flex mt-4  mb-4 arrowIcon'>
              <img src={Arrow} alt="Arrow Icon" onClick={() => navigate('/')} className='mt-2 arrowauthl' />
            </Box>
            <Box className='scale-down position-absolute top-0 end-0 d-flex mt-3 mx-2' mb={4}>
              <Box>
                <Typography className='fontstyleeb fs-5'>Welcome To,</Typography>
                <Typography className='title'>
                  MIT BidSmart
                </Typography>
              </Box>
              <Box sx={{ marginLeft: '16px' }}>
                <Media src="Rfplogoicon.png" alt="Welcome" sx={{ width: '65px', height: '65px' }} />
              </Box>
            </Box>
            <Box width={'80%'}>
              <Typography variant="h3" className='verifyotptxt'>
                <strong>Verify OTP?</strong>
              </Typography>
              <Typography mt={1} mb={1} className='loginsimplfyText'>
                Enter OTP Sent to Your Email
              </Typography>
              <Typography variant='h5' mb={3}>
                {state?.email}<Link to="/forgot-password"> Edit</Link>
              </Typography>
              <Form noValidate onSubmit={handleSubmit}>
                <InputLabel>
                  <Typography className='labels'>
                    OTP
                  </Typography>
                </InputLabel>
                <Box display="flex" justifyContent="space-between">
                  {otp.map((data, index) => (
                    <TextField
                      key={index}
                      id={`otp-input-${index}`}
                      variant="outlined"
                      margin="normal"
                      required
                      className='textfieldotp'
                      inputProps={{
                        style: { textAlign: 'center' },
                        maxLength: 1,
                      }}
                      value={data}
                      onChange={(e) => handleChange(e.target, index)}
                      onKeyDown={(e) => handleKeyDown(e, index)}
                      onPaste={handlePaste}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '15px',
                          width: '3rem',
                          height: '3rem',
                          '&:hover': {
                            borderColor: '#0D6EFD',
                          },
                          '&.Mui-focused': {
                            borderColor: '#0D6EFD',
                            '& fieldset': {
                              borderColor: '#0D6EFD',
                            },
                          },
                        },
                      }}
                    />
                  ))}
                </Box>
                <Typography variant="body2" color="textSecondary" mt={2}>
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Box>
                      {!canResend && `Resend OTP in ${timer}s`}
                    </Box>
                    <Box>
                      {canResend && (
                        <Link href="#" onClick={handleResendOtp}>
                          Resend OTP
                        </Link>
                      )}
                    </Box>
                  </Box>
                </Typography>
                {error && (
                  <Typography color="error" variant="body2" mt={2}>
                    {error}
                  </Typography>
                )}
                <SubmitButton
                  type="submit"
                  fullWidth
                  variant="contained"
                  className='bg-primary text-capitalize rounded-4'
                  sx={{ padding: '10px 10px 10px 10px' }}
                >
                  Verify OTP
                </SubmitButton>
              </Form>
            </Box>
          </FormContainer>
        </Grid>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          action={
            <Button color="inherit" onClick={handleCloseSnackbar}>
              Close
            </Button>
          }
        >
          <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Grid>
    </Root>
  );
};

export default OtpVerify;