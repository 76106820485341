import React, { useState } from 'react';
import {
    Box,
    Button,
    Typography,
    Modal,
    IconButton,
    Divider,
    LinearProgress,
    Tooltip,
} from '@mui/material';
import {
    Close as CloseIcon,
    CheckCircle as CheckCircleIcon,
    Cancel as CancelIcon,
} from '@mui/icons-material';
import PDFICON from '../../assets/Images/PDF.svg';
import { useSnackbar } from '../SnackbarContext';
import './ContentLibrary.css';
import GlobalVariable from "../../services/Global";

const baseApiUrl = GlobalVariable.BASE_API_URL;

const EditFileUpload = ({ open, onClose, setFileBase64, setFileName }) => {
    const [file, setFile] = useState(null);
    const {showSnackbar} = useSnackbar();
    const [progress, setProgress] = useState(0);
    const [uploading, setUploading] = useState(false);
    const [uploadSuccess, setUploadSuccess] = useState(false);

    const handleFileChange = (event) => {
        const uploadedFile = event.target.files[0];
        if (uploadedFile) {
            const validExtensions = ['.pdf', '.doc', '.docx', '.xls', '.xlsx'];
            const fileExtension = uploadedFile.name
                .substring(uploadedFile.name.lastIndexOf('.'))
                .toLowerCase();

            if (!validExtensions.includes(fileExtension)) {
                alert('Invalid file type. Please upload a PDF, Word, or Excel file.');
                return;
            }

            setFile(uploadedFile);
            setProgress(0);
            setUploadSuccess(false);
            setUploading(true);

            const interval = setInterval(() => {
                setProgress((prevProgress) => {
                    if (prevProgress >= 100) {
                        clearInterval(interval);
                        setUploading(false);
                        setUploadSuccess(true);
                        return 100;
                    }
                    return prevProgress + 10;
                });
            }, 500);
        }
    };

    const handleCancelUpload = () => {
        setFile(null);
        setProgress(0);
        setUploading(false);
        setUploadSuccess(false);
    };

    const handleSubmit = () => {
        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                const base64Data = reader.result;
                setFileBase64?.(base64Data);
                setFileName?.(file.name);
                handleCancelUpload();
                onClose();
                // showSnackbar('The new file has been uploaded in the same location as the previous file.', 'success');
            };
            reader.readAsDataURL(file);
        }
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <Box  className='fileuploadeditrfp' sx={{ ...style, width: '600px' }}>
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 5,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>

                <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
                    Add Attachments
                </Typography>
                <Divider />

                <Box
                    sx={{
                        backgroundColor: 'rgba(215, 233, 245)',
                        border: '1px dashed blue',
                        borderRadius: '5px',
                        textAlign: 'center',
                        p: 5,
                        mt: 1,
                        mb: 2,
                    }}
                    onClick={() => document.getElementById('file-input').click()}
                    style={{ cursor: 'pointer' }}
                >
                    <img src={PDFICON} alt="PDF Icon" width="70px" height="84px" />
                    <Typography style={{textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"}}>
                        {!file
                            ? 'Drag and Drop your File here, or Browse Files'
                            : uploading
                            ? `Uploading ${file.name}...`
                            : uploadSuccess
                            ? `${file.name} uploaded successfully`
                            : ''}
                    </Typography>
                    {!file && (
                        <Typography sx={{ color: '#888', fontSize: '12px',textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>
                            Limit 200MB per file • EXCEL, WORD, PDF Files Only
                        </Typography>
                    )}
                    <input
                        accept=".pdf, .doc, .docx, .xls, .xlsx"
                        style={{ display: 'none' }}
                        id="file-input"
                        type="file"
                        onChange={handleFileChange}
                    />
                </Box>

                {file && (
                    <Box sx={{ width: '100%' }}>
                        <Box display="flex" alignItems="center" mb={2}>
                            <img
                                src={PDFICON}
                                alt="PDF Icon"
                                width="30px"
                                height="42px"
                                style={{ marginRight: '10px' }}
                            />
                            <Typography sx={{ flex: 1,textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>{file.name}</Typography>
                            {uploading ? (
                                <Tooltip title="Cancel Upload">
                                    <IconButton onClick={handleCancelUpload}>
                                        <CancelIcon color="error" />
                                    </IconButton>
                                </Tooltip>
                            ) : (
                                uploadSuccess && (
                                    <Tooltip title="Upload Successful">
                                        <IconButton>
                                            <CheckCircleIcon style={{ color: '#4caf50' }} />
                                        </IconButton>
                                    </Tooltip>
                                )
                            )}
                        </Box>
                        <LinearProgress
                            variant="determinate"
                            value={progress}
                            sx={{
                                '& .MuiLinearProgress-bar': {
                                    backgroundColor: '#4caf50',
                                },
                            }}
                        />
                    </Box>
                )}

                <Box display="flex" justifyContent="flex-end" mt={2}>
                    {progress === 100 && (
                        <>
                            <Button
                                onClick={handleCancelUpload}
                                className="rounded-2 text-primary border border-primary mx-2"
                            >
                                Cancel
                            </Button>
                            <Button
                                onClick={handleSubmit}
                                className="rounded-2 bg-primary text-white"
                            >
                                Submit
                            </Button>
                        </>
                    )}
                </Box>
            </Box>
        </Modal>
    );
};

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: '13px',
};

export default EditFileUpload;