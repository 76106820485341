import React, { useState, useEffect } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, CircularProgress } from '@mui/material';
import Container from 'react-bootstrap/Container';
import Pagination from '@mui/material/Pagination';
import Typography from '@mui/material/Typography';
import { TextField, MenuItem, Select } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const headers = [
    'RFP Issuer', 'First Review', 'Conference Due', 'Questionnaire Date', 'Submission Date', 'POC Mail', 'RFP Type', 'Budget Range'
];

function SubmittedDate({ searchTerm, selectedFilters, activeDataa }) {
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [loading, setLoading] = useState(true);

    const totalRows = activeDataa.length;

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }, [activeDataa]);

    const filteredData = activeDataa.filter((row) => {
        const matchesSearchTerm = searchTerm.length > 3 ? row.rfp_issuer.toLowerCase().includes(searchTerm.toLowerCase()) : true;
        return matchesSearchTerm;
    });

    const paginatedData = filteredData.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);

    const handleChangePage = (event, value) => {
        setCurrentPage(value);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setCurrentPage(1);
    };

    const handleRowClick = (row) => {
        navigate(`/rfp-preview/${row.rfpid}`);
    };

    return (
        <div>
            <Container fluid disableGutters>
                {loading ? (
                    <Box display="flex" justifyContent="center" alignItems="center" height="300px">
                        <CircularProgress className=' text-primary' />
                    </Box>
                ) : (
                    <>
                        <TableContainer
                            component={Paper}
                            className='mt-3'
                            sx={{
                                width: '100%',
                                height: '288px',
                                overflow: 'auto',
                                '&::-webkit-scrollbar': {
                                    height: '3px',
                                    width: '3px',
                                },
                                '&::-webkit-scrollbar-thumb': {
                                    backgroundColor: '#888',
                                    borderRadius: '6px',
                                },
                                '&::-webkit-scrollbar-thumb:hover': {
                                    backgroundColor: '#555',
                                    cursor: 'pointer',
                                },
                            }}
                        >
                            <Table aria-label="simple table" sx={{ width: '100%' }}>
                                <TableHead>
                                    <TableRow>
                                        {headers.map(header => (
                                            <TableCell key={header} align="left" className="fontstyleeb" sx={{ width: 'auto', whiteSpace: 'nowrap', fontWeight: 'bold' }}>
                                                {header}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody className="position-relative">
                                    {paginatedData.length > 0 ? (
                                        paginatedData.map((row) => (
                                            <TableRow key={row.rfpid} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                <TableCell align="Left" className='fontstyleeb text-primary cursor' sx={{ width: 'auto', whiteSpace: 'nowrap' }} onClick={() => handleRowClick(row)}>{row.rfp_issuer} </TableCell>
                                                <TableCell align="left" sx={{ width: 'auto', whiteSpace: 'nowrap' }}> {row.first_review} </TableCell>
                                                <TableCell align="left" sx={{ whiteSpace: 'nowrap' }}> {row.conference_date} </TableCell>
                                                <TableCell align="left" sx={{ width: 'auto', whiteSpace: 'nowrap' }}> {row.questionnaire_date} </TableCell>
                                                <TableCell align="left" sx={{ width: 'auto', whiteSpace: 'nowrap' }}> {row.submission_date} </TableCell>
                                                <TableCell align="left" sx={{ width: 'auto', whiteSpace: 'nowrap' }}>{row.contact_email}</TableCell>
                                                <TableCell align="left" sx={{ width: 'auto', whiteSpace: 'nowrap' }}> {row.rfp_type} </TableCell>
                                                <TableCell align="right" sx={{ width: "auto", whiteSpace: "nowrap" }}>{row.budget_range}</TableCell>

                                                <TableCell align="left"></TableCell>
                                            </TableRow>
                                        ))
                                    ) : (
                                        <Box
                                            sx={{
                                                position: 'absolute',
                                                top: 100,
                                                left: 450,
                                            }}
                                        >
                                            <Typography variant="h6">No Records Found</Typography>
                                        </Box>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        {totalRows > 10 && paginatedData.length > 0 && (
                            <Box display="flex" justifyContent="space-between" alignItems="center" mt={1}>
                                <Box display="flex" alignItems="center">
                                    <Typography variant="body2" className="mx-2 text-secondary">
                                        Rows per page:
                                    </Typography>
                                    <Select
                                        value={rowsPerPage}
                                        onChange={handleChangeRowsPerPage}
                                        size="small"
                                        sx={{ marginRight: 2 }}
                                    >
                                        <MenuItem value={10}>10</MenuItem>
                                        <MenuItem value={20}>20</MenuItem>
                                        <MenuItem value={30}>30</MenuItem>
                                    </Select>
                                </Box>
                                <Pagination
                                    count={Math.ceil(filteredData.length / rowsPerPage)}
                                    page={currentPage}
                                    onChange={handleChangePage}
                                    sx={{
                                        '& .Mui-selected': {
                                            color: '#1976d2',
                                        },
                                    }}
                                />
                                <Box display="flex" alignItems="center">
                                    <Typography variant="body2" className="mx-1 text-secondary">
                                        Go to page:
                                    </Typography>
                                    <TextField
                                        type="text"
                                        value={currentPage}
                                        onChange={handleChangePage}
                                        variant="standard"
                                        InputProps={{
                                            disableUnderline: true,
                                            sx: {
                                                width: '60px',
                                                '& input': {
                                                    textAlign: 'center',
                                                },
                                            },
                                        }}
                                        size="small"
                                        placeholder="___"
                                    />
                                </Box>
                            </Box>
                        )}
                    </>
                )}
            </Container>
        </div>
    );
}

export default SubmittedDate;