import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Container,
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TextField,
  IconButton,
  InputAdornment,
  CircularProgress,
  Divider,
} from "@mui/material";
import {
  Close as CloseIcon,
  Search as SearchIcon,
  Delete,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "../SnackbarContext";
import Modal from "@mui/material/Modal";
import GlobalVariable from "../../services/Global";

const baseApiUrl = GlobalVariable.BASE_API_URL;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 650,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
  borderRadius: 4,
};

function Content_Library() {
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const [searchTerm, setSearchTerm] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [contentToDelete, setContentToDelete] = useState(null);

  const refreshtoken = async () => {
    const storedData = JSON.parse(localStorage.getItem("userDetails"));
    const userId = storedData.userDetails.id;
    try {
      const response = await axios.post(`${baseApiUrl}/users/refreshtoken`,
        JSON.stringify({ userid: userId }),
        { headers: { "Content-Type": "application/json" } }
      );
      if (response?.data?.status_code === 200) {
        const { userDetails, access_token } = response.data;
        localStorage.setItem(
          "userDetails",
          JSON.stringify({ userDetails, access_token })
        );
        window.location.reload();
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const storedData = JSON.parse(localStorage.getItem("userDetails"));
      let accessToken = storedData?.access_token;

      if (searchTerm.length < 3 && searchTerm !== "") return;
      
      try {
        const response = await axios.get(`${baseApiUrl}/contentlibrary/contentlibrary_list?search=${searchTerm}&page=0&pageSize=10`, {
          headers: { Authorization: `Bearer ${accessToken}` },
        });
        setLoading(true);
        setData(response.data.data);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          refreshtoken();
        } else {
          console.error("Error fetching data:", error);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [searchTerm]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleClearSearch = () => {
    setSearchTerm("");
  };

  const handleRowClick = (row) => {
    navigate(`/section-details/${row.id}`, { state: { row } });
  };

  const truncateAndRemoveTags = (text, maxLength) => {
    if (!text) return "";
    const strippedText = text.replace(/<[^>]+>/g, "").trim();
    return strippedText.length <= maxLength
      ? strippedText
      : `${strippedText.substring(0, maxLength)}...`;
  };

  const handleDeleteIconClick = (prompt) => {
    setContentToDelete(prompt);
    setDeleteConfirmOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteConfirmOpen(false);
    setContentToDelete(null);
  };

  const handleDeleteContent = async (id) => {
    const storedData = JSON.parse(localStorage.getItem("userDetails"));
    const accessToken = storedData.access_token;

    try {
      const response = await axios.delete(`${baseApiUrl}/contentlibrary/delclsection`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
        data: { id },
      });

      if (response.data.status_code === 200) {
        setData(data.filter((prompt) => prompt.id !== id));
        setDeleteConfirmOpen(false);
        showSnackbar("Content deleted successfully.");
      } else {
        console.error("Failed to delete prompt:", response.data.message);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        console.error(
          "Error:",
          error.response.data.msg || error.response.data.message
        );
      } else {
        console.error("Error deleting prompt:", error);
      }
    }
  };

  return (
    <Container
      fluid
      className="rfpDtailsContainer"
      maxWidth="100%"
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          bgcolor: "#fff",
          flexGrow: 1,
          mt: 2,
          boxShadow: 10,
          borderRadius: "10px",
          padding: "20px",
          height: "100%"
        }}
      >
        <Box
          className="search-and-add-section"
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            mb: 2,
          }}
        >
          <Typography
            className="MuiTypography-h5"
            variant="h5"
            sx={{ fontWeight: 600 }}
          >
            Content Library
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              alignItems: "center",
              mt: { xs: 2, md: 0 },
            }}
          >
            <TextField
              className="MuiTextField-root search-bar"
              placeholder="Search by RFP Name"
              variant="outlined"
              value={searchTerm}
              onChange={handleSearchChange}
              size="small"
              sx={{
                fontSize: { xs: "12px", md: "12px" },
                marginRight: { sm: 2, xs: 0 },
                mb: { xs: 1, sm: 1 },
                "& .MuiOutlinedInput-root": {
                  borderRadius: "9px",
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{ opacity: "40%" }} />
                  </InputAdornment>
                ),
                endAdornment: searchTerm && (
                  <InputAdornment position="end">
                    <IconButton size="small" onClick={handleClearSearch}>
                      <CloseIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button
              variant="contained"
              onClick={() => navigate("/add-new-section")}
              className="Buttons_ESD3 MuiButton-AddNew bg-primary text-capitalize rounded-3"
              sx={{
                bgcolor: "#429bf5",
                mt: { xs: 1, sm: -1 },
              }}
            >
              Add New Section
            </Button>
          </Box>
        </Box>
        <TableContainer
          className="table-container"
          component={Paper}
          sx={{
            width: "100%",
            boxShadow: 2,
            padding: 2,
            borderRadius: "15px",
            flexGrow: 1,
          }}
        >

          <Table>
            <TableHead className="TableData">
              <TableRow>
                <TableCell className="fontstyleeb">S.No</TableCell>
                <TableCell
                className="cell-border-right fontstyleeb"
                >
                  Sections Name
                </TableCell>
                <TableCell className="fontstyleeb">Description</TableCell>
                <TableCell className="fontstyleeb">Last Updated</TableCell>
                <TableCell className="fontstyleeb">Delete</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading && (
                <Box
                  className="circular-progress"
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    zIndex: 1000,
                  }}
                >
                  <CircularProgress className=" text-primary" />
                </Box>
              )}
              {data.map((row, index) => (
                <TableRow key={row.id}>
                  <TableCell sx={{ fontSize: { xs: "12px", md: "14px" } }}>
                    {index + 1}
                  </TableCell>
                  <TableCell
                    className="fontstylee text-primary cell-border-right  "
                    sx={{
                      fontSize: { xs: "12px", md: "13px", lg: "14px" },
                      cursor: "pointer",
                    }}
                    onClick={() => handleRowClick(row)}
                  >
                    {row.content_name}
                  </TableCell>
                  <TableCell
                    sx={{ fontSize: { xs: "10px", md: "11px", lg: "12px" } }}
                  >
                    {truncateAndRemoveTags(row.content_description, 50)}
                  </TableCell>
                  <TableCell
                    sx={{ fontSize: { xs: "10px", md: "11px", lg: "12px" } }}
                  >
                    {row.last_updated}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      color="error"
                      onClick={() => handleDeleteIconClick(row)}
                    >
                      <Delete
                       className="delete-icon"
                      />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Modal
          open={deleteConfirmOpen}
          onClose={handleCloseDeleteDialog}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={{ ...style, width: "500px" }}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="baseline"
            >
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Confirm Delete
              </Typography>
              <CloseIcon
                onClick={handleCloseDeleteDialog}
                style={{ cursor: "pointer" }}
              />
            </Box>
            <Divider style={{ borderColor: "#adadad" }} />
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Are you sure you want to delete?
            </Typography>
            <Box mt={4} display="flex" justifyContent="end">
              <Button
                variant="outlined"
                className="border-primary text-primary rounded-3"
                onClick={handleCloseDeleteDialog}
              >
                No
              </Button>
              <Button
                variant="contained"
                className="bg-primary mx-2 rounded-3"
                onClick={() => handleDeleteContent(contentToDelete.id)}
              >
                Yes
              </Button>
            </Box>
          </Box>
        </Modal>
      </Box>
    </Container>
  );
}

export default Content_Library;