import React, { useState, useCallback, useRef, useEffect } from 'react';
import { Card, CardContent, Box, Table, TableBody, TableCell, TableHead, TableRow, IconButton, Typography, Button, Modal, LinearProgress, Divider } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import { Close as CloseIcon, CheckCircle as CheckCircleIcon, Cancel as CancelIcon, Pause as PauseIcon, PlayArrow as PlayArrowIcon } from '@mui/icons-material';
import PDFICON from '../../../assets/Images/PDF.svg'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 540,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 3,
  borderRadius: 4,
};

const DocumentTable = () => {
  const [file, setFile] = useState(null);
  const [progress, setProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [paused, setPaused] = useState(false);
  const [resumeRequested, setResumeRequested] = useState(false);
  const uploadTimer = useRef(null);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const documents = [
    { name: 'Microsoft RFP.pdf', type: 'RFP', date: 'May-25-2024' },
    { name: 'Proposal format.pdf', type: 'Support', date: 'May-24-2024' },
    { name: 'Project scope.pdf', type: 'Addendum', date: 'May-24-2024' },
    { name: 'Deadlines.xls', type: 'Addendum', date: 'May-23-2024' },
    { name: 'Budget form.doc', type: 'Support', date: 'May-22-2024' },
  ];

  const simulateFileUpload = useCallback((uploadedFile, currentProgress = 0) => {
    setUploading(true);
    setPaused(false);
    uploadTimer.current = setInterval(() => {
      if (paused) {
        clearInterval(uploadTimer.current);
        setUploading(false);
      } else if (currentProgress < 100) {
        currentProgress += 10;
        setProgress(currentProgress);
      } else {
        clearInterval(uploadTimer.current);
        setUploading(false);
        setUploadSuccess(true);
      }
    }, 500);
  }, [paused]);

  const handleFileChange = (event) => {
    const uploadedFile = event.target.files[0];
    if (uploadedFile) {
      setFile(uploadedFile);
      simulateFileUpload(uploadedFile);
      document.getElementById('raised-button-file').click();
    }
  };

  const handleCancelUpload = () => {
    cleanUp();
    setProgress(0);
    setFile(null);
    setUploadSuccess(false);
    document.getElementById('raised-button-file').value = '';
  };

  const handlePauseUpload = () => {
    setPaused(true);
    setUploading(false);
    clearInterval(uploadTimer.current);
  };

  const handleResumeUpload = () => {
    if (paused) {
      setPaused(false);
      setResumeRequested(true);
    }
  };

  useEffect(() => {
    if (resumeRequested && !uploading) {
      setResumeRequested(false);
      simulateFileUpload(file, progress);
    }
  }, [resumeRequested, file, progress, uploading, simulateFileUpload]);

  const cleanUp = () => {
    clearInterval(uploadTimer.current);
    setUploading(false);
    setPaused(false);
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Card>
        <CardContent>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
            <Typography variant="h6">
              List of Documents
            </Typography>
            <Button className='bg-primary text-capitalize rounded-3' variant="contained" color="primary" onClick={handleOpen}>
              Add
            </Button>
          </Box>
          <Card sx={{ width: '100%', height: 'auto' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Document Name</TableCell>
                  <TableCell>Document Type</TableCell>
                  <TableCell>Uploaded Date</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {documents.map((doc, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <a href="#">{doc.name}</a>
                    </TableCell>
                    <TableCell>{doc.type}</TableCell>
                    <TableCell>{doc.date}</TableCell>
                    <TableCell>
                      <IconButton color="primary">
                        <VisibilityIcon />
                      </IconButton>
                      <IconButton color="error">
                        <DeleteIcon />
                      </IconButton>
                      {index === 2 || index === 3 ? (
                        <IconButton color="secondary">
                          <FileCopyIcon />
                        </IconButton>
                      ) : null}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Card>
        </CardContent>
      </Card>

      <Modal open={open} onClose={handleClose} aria-labelledby="modal-title" aria-describedby="modal-description">
        <Box sx={{ ...style, width: '600px' }}>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 5,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" component="h2" textAlign="left" className='fw-bold fs-6 pb-1' sx={{ marginTop: '-13px' }}>
            Add RFP
          </Typography>
          <Divider />
          <Typography variant="h5" component="h2" textAlign="center" className='fw-500 mt-2 fs-7'>
            Upload RFP document
          </Typography>
          <Box sx={{
            backgroundColor: 'rgba(215, 233, 245)',
            border: '1px dashed blue',
            borderRadius: '5px',
            textAlign: 'center',
            p: 5,
            mt: 1,
            mb: 2,
            position: 'relative',
            width: '93%',
            ml: 2
          }}>
            <Box onClick={() => document.getElementById('raised-button-file').click()}>
              <img src={PDFICON} alt='PDFICON' width={'70px'} height={'84px'} style={{ cursor: 'pointer' }} className='pb-2' />
            </Box>
            <Typography style={{}}>
              {uploading && <>Uploading {file.name}...</>}
              {uploadSuccess && <>{file.name} uploaded successfully</>}
            </Typography>
            {!uploading && !uploadSuccess && (
              <>Drag and Drop your File here, or{' '}
                <label htmlFor="raised-button-file" style={{ color: '#007bff', cursor: 'pointer' }}>
                  Browse Files
                </label>
                <Typography sx={{ color: '#888', fontSize: '12px' }}>
                  Limit 2MB per file • PDF Files Only
                </Typography>
              </>
            )}

            <input
              accept=".pdf"
              style={{ display: 'none' }}
              id="raised-button-file"
              type="file"
              onChange={handleFileChange}
            />
          </Box>

          {!file && (
            <Box textAlign="center" mt={0} sx={{ width: '92%', marginLeft: '18px' }}>
              <Divider>
                <Typography align="center" sx={{ color: '#888' }}>
                  Or
                </Typography>
              </Divider>
              <Button variant="contained" onClick={() => navigate('/AddNewRfp')} className='bg-primary mt-3 w-50 rounded-2'>
                Enter RFP Details
              </Button>
            </Box>
          )}

          {file && (
            <Box sx={{ width: '93%', marginLeft: '15px' }}>
              <div className='border border-1px solid grey p-2'>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                  <Box display="flex" alignItems="center">
                    <img src={PDFICON} alt='PDFICON' width={'30px'} height={'42px'} style={{ cursor: 'pointer' }} className='pb-2 mx-1' />
                    <div style={{ maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                      <Typography style={{ textDecoration: 'none' }}>
                        {file.name} ({(file.size / 1024).toFixed(2)} KB)
                      </Typography>
                    </div>
                  </Box>
                  <Box display="flex" alignItems="center">
                    {uploading ? (
                      <>
                        <IconButton onClick={handleCancelUpload}>
                          <CancelIcon color="error" />
                        </IconButton>
                        <IconButton onClick={handlePauseUpload}>
                          <PauseIcon />
                        </IconButton>
                      </>
                    ) : (
                      <>
                        {uploadSuccess ? (
                          <IconButton>
                            <CheckCircleIcon style={{ color: '#F2FDF5' }} />
                          </IconButton>
                        ) : (
                          <IconButton onClick={handleResumeUpload}>
                            <PlayArrowIcon />
                          </IconButton>
                        )}
                      </>
                    )}
                  </Box>
                </Box>
                <LinearProgress variant="determinate" value={progress} sx={{
                  '& .MuiLinearProgress-bar': {
                    backgroundColor: '#4caf50',
                  },
                  mt: 1,
                }} />
              </div>
            </Box>
          )}

          <Box display="flex" justifyContent="flex-end" mt={2}>
            {progress === 100 && (
              <div>
                <Button color="primary" onClick={handleClose} className='rounded-2 text-primary border border-primary mx-2'>
                  Cancel
                </Button>
                <Button color="primary" className='rounded-2 bg-primary text-white'>
                  Save
                </Button>
              </div>
            )}
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default DocumentTable;