import React, { useState, useCallback, useRef } from 'react';
import { Box, Button, Typography, Modal, IconButton, LinearProgress, Divider, CircularProgress} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import PDFICON from '../../assets/Images/PDF.svg';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useSnackbar } from '../SnackbarContext';
import GlobalVariable from "../../services/Global";

const baseApiUrl = GlobalVariable.BASE_API_URL;

const FileUploadModal = ({ open, onClose }) => {
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const [file, setFile] = useState(null);
  const [progress, setProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pdfText, setPdfText] = useState('');
  const [isCloseDisabled, setIsCloseDisabled] = useState(false);

  const uploadTimer = useRef(null);

  const simulateFileUpload = useCallback((currentProgress = 0) => {
    uploadTimer.current = setInterval(() => {
      if (currentProgress < 90) {
        currentProgress += 5;
        setProgress(currentProgress);
      }
    }, 500);
  }, []);

  const uploadFile = async (base64File, filename) => {
    const storedData = JSON.parse(localStorage.getItem('userDetails'));
    const userid = storedData.userDetails.id;
    try {
      const response = await axios.post(`${baseApiUrl}/dashboard/addrfpfileupload`, {
        userid: userid,
        base64: base64File,
        filename: filename
      }, {
        headers: { 'Content-Type': 'application/json' }
      });
      setProgress(100);

      if (response.data.status_code === 200) {

        const result = response.data.result;
        setPdfText(result);

        showSnackbar(`${filename} uploaded successfully.`, "success");
        navigate('/dashboard/addNewRfp/ExtrctedPdfDocument', { state: { pdfText: result } });
        return result;
      }
    } catch (error) {
      console.error('Upload error:', error);
      if (error.status === 500) {
        showSnackbar('This File name not supported. Please change file name and upload it.', 'error');
        setFile(null)
      }
      setProgress(0);
    } finally {
      setLoading(false);
      setUploading(false);
      clearInterval(uploadTimer.current);
      setIsCloseDisabled(false);
    }
  };

  const resetState = () => {
    setFile(null);
    setProgress(0);
    setUploading(false);
    setLoading(false);
    setPdfText('');
    onClose();
  };


  const handleFileChange = async (event) => {
    const uploadedFiles = event.target.files[0];
    const fileSizeInMB = uploadedFiles.size / (1024 * 1024);
    if (fileSizeInMB > 3) {
      showSnackbar('File size exceeds greater than 3MB. Please upload a smaller file.', 'error');
      event.target.value = null;
    } else {
      if (uploadedFiles) {
        setFile(uploadedFiles);
      }
    }
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result.split(',')[1]);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const handleView = async () => {
    if (file) {
      setUploading(true);
      setLoading(true);
      setProgress(0);
      setIsCloseDisabled(true);
      simulateFileUpload();
      const base64File = await convertToBase64(file);
      const result = await uploadFile(base64File, file.name);

      onClose();
    }
  };

  return (
    <Modal open={open} onClose={onClose} aria-labelledby="modal-title" aria-describedby="modal-description">
      <Box sx={{ ...style, width: '100%', height: '100%' }} className='fileuploadModal'>
        {loading && (
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 1000,
            }}
          >
            <CircularProgress className=' text-primary' />
          </Box>
        )}

        <IconButton
          aria-label="close"
          onClick={resetState}
          disabled={isCloseDisabled}
          sx={{
            position: 'absolute',
            right: 8,
            top: 5,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>

        <Typography variant="h6" component="h2" textAlign="left" className='fw-bold fs-6 pb-1 addRfpText' sx={{ marginTop: '-13px' }}>
          Add RFP
        </Typography>

        <Divider />

        <Typography variant="h5" component="h2" textAlign="center" className='fw-500 mt-2 fs-7 addRfpText'>
          Upload RFP document
        </Typography>

        <Box sx={{
          backgroundColor: 'rgba(215, 233, 245)',
          border: '1px dashed blue',
          borderRadius: '5px',
          textAlign: 'center',
          p: 5,
          mt: 1,
          mb: 2,
          position: 'relative',
          width: '100%',
          ml: 2
        }}
          className='fileuploadBox docuploadBox'
        >
          <Box onClick={() => document.getElementById('raised-button-file').click()}>
            <img src={PDFICON} alt='PDFICON' width={'70px'} height={'84px'} style={{ cursor: 'pointer' }} className='pb-2 addRfpFile' />
          </Box>

          <Typography className='fileuploadText' style={{ textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>
            {!file && (
              <>
                Drag and Drop your File here, or{' '}
                <label htmlFor="raised-button-file" className='fileuploadTextsub' style={{ color: '#007bff', cursor: 'pointer' }}>
                  Browse Files
                </label>
              </>
            )}
            {file && <>Selected file: {file.name}</>}
            <Typography className='subtextfu' sx={{ color: '#888', fontSize: '12px' }}>
              Limit 2MB per file • PDF Files Only
            </Typography>
          </Typography>

          <input
            accept=".pdf"
            style={{ display: 'none' }}
            id="raised-button-file"
            type="file"
            onChange={handleFileChange}
          />
        </Box>
        {!file && (
          <Box textAlign="center" mt={0} sx={{ width: '92%', marginLeft: '18px' }}>
            <Divider>
              <Typography align="center" sx={{ color: '#888' }}>
                Or
              </Typography>
            </Divider>
            <Button variant="contained" onClick={() => navigate('/AddNewRfp')} className='bg-primary mt-3 w-50 rounded-2 text-capitalize RfpDetailsButton'>
              Enter RFP Details
            </Button>
          </Box>
        )}

        {uploading && (
          <Box sx={{ width: '100%', }} className='fileuploadBox'>
            <div className='border border-1px solid grey p-2'>
              <Box alignItems="center" justifyContent="space-between">
                <Box display="flex" alignItems="center">
                  <img src={PDFICON} alt='PDFICON' width={'30px'} height={'42px'} style={{ cursor: 'pointer' }} className=' mx-1' />
                  <Box ml={2}>
                    <div style={{ maxWidth: '280px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                      <Typography className='pdfnamefu' style={{ textDecoration: 'none', width: "200px", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>
                        {file.name} ({file?.size} bytes)
                      </Typography>
                    </div>
                  </Box>

                </Box>
              </Box>
              <Box sx={{ width: '100%', marginTop: '15px' }} className='progressbar'>

                <LinearProgress variant="determinate" value={progress} sx={{
                  '& .MuiLinearProgress-bar': {
                    backgroundColor: '#4caf50',
                  },

                }} />
              </Box>
            </div>
          </Box>
        )}

        <Box sx={{ display: 'flex', justifyContent: 'end' }} className='mt-2'>
          {file && (
            <Button color="primary" onClick={handleView} disabled={loading} className='rounded-2 bg-primary text-white viewButton'>
              View
            </Button>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  borderRadius: '8px',
  boxShadow: 24,
  p: 5
};

export default FileUploadModal;