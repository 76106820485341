import React, { useState, useEffect } from 'react';
import { Container, Typography, Paper, Button, Box, Link, Drawer, Table, TableBody, TableCell, CircularProgress, TableContainer, TableHead, TableRow, Divider, useMediaQuery, Modal } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PDF from '../../assets/Images/PDF.svg';
import axios from 'axios';
import './ContentLibrary.css';
import GlobalVariable from "../../services/Global";

const baseApiUrl = GlobalVariable.BASE_API_URL;

const SectionDetails = () => {
  const navigate = useNavigate();
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const { id } = useParams();
  const [sectionData, setSectionData] = useState({});
  const [versionHistory, setVersionHistory] = useState([]);
  const [isPdfOpen, setPdfOpen] = useState(false);
  const isMobile = useMediaQuery('(max-width:600px)');
  const [loading, setLoading] = useState(false);


  const storedData = JSON.parse(localStorage.getItem('userDetails'));
  let token = storedData?.access_token;

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  const refreshtoken = async () => {
    const userId = storedData.userDetails.id;
    try {
      const data = {
        userid: userId,
      };
      const response = await axios({
        method: "POST",
        url: `${baseApiUrl}/users/refreshtoken`,
        data: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response?.data?.status_code === 200) {
        const { userDetails, access_token } = response.data;

        const userData = {
          userDetails,
          access_token,
        };
        localStorage.setItem("userDetails", JSON.stringify(userData));
        token = access_token;
        window.location.reload();
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchSectionDetails = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${baseApiUrl}/contentlibrary/getspecific?id=${id}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.data.status_code === 200) {
        const { data } = response.data;
        setSectionData(data);
        setVersionHistory(data.version_history);
        setLoading(false);

      }
    } catch (error) {
      if (error.response?.status === 401) {
        await refreshtoken();
        setLoading(false);

      } else {
        setLoading(false);

      }
    }
  };

  const togglePdfView = () => {
    setPdfOpen(!isPdfOpen);
  };

  useEffect(() => {
    fetchSectionDetails();
  }, []);

  const Sidebar = ({ onClose }) => (
    <Box p={3} width={isMobile ? '100%' : '400px'} role="presentation">
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h5" className='fontstyleeb' gutterBottom>
          Version History
        </Typography>
        <CloseIcon onClick={onClose} style={{ cursor: 'pointer' }} />
      </Box>
      <Divider sx={{ marginBottom: 1 }} />
      <TableContainer component={Paper} sx={{ borderColor: 'ActiveBorder', borderRadius: '20px', marginTop: '15px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className='fontstyleeb'>S.No</TableCell>
              <TableCell className='fontstyleeb'>Version</TableCell>
              <TableCell className='fontstyleeb'>Updated On</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {versionHistory.map((version, index) => (
              <TableRow key={version.slno}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{version.version}</TableCell>
                <TableCell>{version.updated_on}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Divider style={{ marginTop: '34px', borderColor: 'black' }} />
      <Row style={{ marginTop: '15px' }}>
        <Col lg={{ span: 3, offset: 9 }}>
          <Button
            variant="contained"
            className='bg-primary text-capitalize rounded-3'
            fullWidth
            onClick={onClose}
          >
            Close
          </Button>
        </Col>
      </Row>
    </Box>
  );


  return (
    <Container className='rfpDtailsContainer' maxWidth="100%">
      <Box mt={2}>
        <Paper elevation={3} style={{ padding: 20, borderRadius: '15px' }}>
          <Box display="flex" justifyContent="space-between">
            <Typography className='fontstyleeb' variant="h5" gutterBottom>
              Section Details
            </Typography>
            <Button
              variant="outlined"
              onClick={() => navigate(-1)}
              sx={{ marginRight: 2, color: '#429bf5', borderColor: '#429bf5' }}
              className='buttonsd border-primary text-capitalize rounded-3'
            >
              Back
            </Button>
          </Box>
          <Box className='d-flex mb-4 mt-2'>
            {loading && (
              <Box
                className="circular-progress"
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  zIndex: 1000,
                }}
              >
                <CircularProgress className=" text-primary" />
              </Box>
            )}
            <Box>
              <Typography className='fontstylee' sx={{ fontSize: 12 }}>Section Name</Typography>
              <Typography sx={{ fontSize: 12 }}>{sectionData && sectionData?.content_name}</Typography>
            </Box>
            <Box className='mx-4'>
              <Typography className='fontstylee' variant="subtitle1" sx={{ fontSize: 12 }}>
                Last Updated{' '}
                <Link onClick={toggleSidebar} className='link-underline' style={{ cursor: 'pointer', color: '#0175FF', fontSize: 12 }}>
                  Version History
                </Link>
              </Typography>
              <Typography variant="subtitle1" sx={{ fontSize: 12 }}>
                {sectionData.last_updated}
              </Typography>
            </Box>
          </Box>
          <Typography className='fontstylee' gutterBottom>
            Description
          </Typography>
          <Typography
            variant="body1"
            paragraph
            sx={{ textAlign: "justify", fontSize: 12 }}
            dangerouslySetInnerHTML={{ __html: sectionData.content_description }}
          />

          <Box alignItems="center" mb={2}>
            {sectionData.content_file ? (
              <>
                <Typography variant="h6" gutterBottom>
                  Attachments
                </Typography>
                <Typography variant="body1" style={{ color: '#0175FF'}}
                >{sectionData?.content_filename}</Typography>
                <img src={PDF} alt="PDF Icon" style={{ cursor: 'pointer', color: '#0175FF', marginLeft: 10 }}
                  onClick={togglePdfView} />
                <Typography variant="body1" style={{ cursor: 'pointer', color: '#0175FF', marginLeft: '10px', marginTop: '8px' }}
                  className='link-underline' onClick={togglePdfView}>View File</Typography>
              </>
            ) : (
              <Typography variant="body1"></Typography>
            )}
          </Box>
          <Box display="flex" justifyContent="flex-end">
            <Button variant="outlined" onClick={() => navigate(-1)} className='border-primary text-capitalize rounded-3' sx={{ marginRight: 2, color: '#0D6EFD' }}>
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={() => navigate(`/edit-section-details/${id}`, { state: { sectionName: sectionData.content_name, description: sectionData.content_description, attachment: sectionData.content_file, filename: sectionData?.content_filename } })}
              sx={{ bgcolor: '#429bf5' }}
              className='bg-primary text-capitalize rounded-3'
            >
              Edit
            </Button>
          </Box>
        </Paper>
      </Box>
      <Drawer anchor="right" open={isSidebarOpen} onClose={toggleSidebar}>
        <Sidebar onClose={toggleSidebar} />
      </Drawer>
      <Modal open={isPdfOpen} onClose={togglePdfView}>
  <Box
    width={isMobile ? "100%" : "80%"}
    height={isMobile ? "100%" : "80%"}
    margin="auto"
    bgcolor="white"
    display="flex"
    flexDirection="column"
    alignItems="center"
    borderRadius={2}
    justifyContent="center"
    mt={isMobile ? 0 : "5%"}
    p={isMobile ? 1 : 2}
    sx={{
      overflowY: isMobile ? "scroll" : "hidden",
    }}
  >
    <CloseIcon
      onClick={togglePdfView}
      style={{
        alignSelf: "flex-end",
        cursor: "pointer",
        marginBottom: "10px",
        marginRight: isMobile ? "10px" : "0",
      }}
    />
    {sectionData.content_file && (
      <iframe
        src={sectionData.content_file}
        width="100%"
        height="100%"
        title="PDF Viewer"
        style={{
          border: "none",
          margin: 0,
          padding: 0,
        }}
      />
    )}
  </Box>
</Modal>

    </Container>
  );
};

export default SectionDetails;